import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ElecCandis.module.css'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'

import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import { stringAvatar80, stringAvatar80Single } from '../../../Utils/MUITheme/MUITheme'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import Switch from '@mui/material/Switch'

function AddCandiOnlineVote({
   candidatesListAccepted,
   uploadingOnlineVote,
   uploadingFailedOnlineVote,
   uploadedOnlineVote,
   handleAddOnlineVote,
}) {
   const [onlineVoteDetails, setOnlineVoteDetails] = useState({
      Candidate_id: '',
   })
   const [selectedCandi, setSelectedCandi] = useState('')
   const focusRef = useRef(null)

   // const [selectedIndex, setSelectedIndex] = useState('')

   useEffect(() => {
      console.log('on line vote details on change: ', onlineVoteDetails)
   }, [onlineVoteDetails])

   function handleOnlineVoteDetails(e, item) {
      e.preventDefault()
      setOnlineVoteDetails({ Candidate_id: item.Candidate_id })
      setSelectedCandi(item)
   }

   useEffect(() => {
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }, [selectedCandi])

   const handleSubmitOnlineVote = async (e) => {
      e.preventDefault()

      if (onlineVoteDetails.Candidate_id !== '') {
         console.log('status Details before submit: ', onlineVoteDetails)

         handleAddOnlineVote(onlineVoteDetails)
      }
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            // '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <List dense>
            {candidatesListAccepted &&
               candidatesListAccepted.length > 0 &&
               candidatesListAccepted.map((item, index) => (
                  <div key={item.Candidate_id}>
                     <ListItem
                        sx={{ pl: 0, ml: 0 }}
                        key={item.Candidate_id}
                        secondaryAction={
                           <Box sx={{ ml: 'auto' }}>
                              <Switch
                                 checked={item.Candidate_id === onlineVoteDetails.Candidate_id}
                                 onChange={(e) => {
                                    handleOnlineVoteDetails(e, item)
                                 }}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />
                           </Box>
                        }
                     >
                        <ListItemAvatar>
                           {item.Name &&
                              (countWordsUsingReplace(item.Name) > 1 ? (
                                 <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                              ) : (
                                 <Avatar {...stringAvatar80Single(item.Name)} alt="Travis Howard" src={item.Image} />
                              ))}
                        </ListItemAvatar>

                        <ListItemText
                           sx={{ ml: 1 }}
                           secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           primary={
                              <>
                                 {item.Name !== 'Zznota' ? (
                                    <strong>{item.Name.toUpperCase()}</strong>
                                 ) : (
                                    <strong>NOTA</strong>
                                 )}

                                 {item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameLocal}
                                    </>
                                 )}
                                 {!item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameHindi}
                                    </>
                                 )}
                              </>
                           }
                           component={'span'}
                           secondary={
                              <>
                                 <>
                                    <img src={item.PartyFlag} className={styles.imgX3} />
                                    {item.PartySymbol ? (
                                       <img src={item.PartySymbol} className={styles.imgX3} />
                                    ) : (
                                       <img src={item.AllottedSymbol} className={styles.imgX3} />
                                    )}
                                    <br />
                                 </>
                                 <>
                                    {<strong> Party: </strong>}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {item.PartyName}
                                    </Typography>
                                 </>
                              </>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
         </List>
         {selectedCandi !== '' && (
            <Box ref={focusRef} sx={{ mb: 0.5 }}>
               <Alert variant="outlined" severity="warning">
                  You have chosen to support <strong>{selectedCandi.Name}</strong>.
                  <br />
                  आपने <strong>{selectedCandi.NameHindi}</strong> का समर्थन करना चुना है |
               </Alert>
            </Box>
         )}

         <Alert variant="outlined" severity="error" sx={{ color: 'Crimson' }}>
            Please vote your support inside <strong>your constituency strictly</strong>. You will not be allowed to vote
            your support for another constituency.
            <br />
            <strong>Your user_id will be automatically attached to this constituency for next two years.</strong>
            <br />
            कृपया <strong>अपने निर्वाचन क्षेत्र</strong> के अंदर ही अपने समर्थन में मतदान करें। आपको किसी अन्य निर्वाचन
            क्षेत्र में अपने समर्थन में मतदान करने की अनुमति नहीं दी जाएगी।
            <br />
            <strong>आपका user_id अगले दो वर्षों के लिए स्वचालित रूप से इस निर्वाचन क्षेत्र से जुड़ जाएगा।</strong>
         </Alert>

         <Box sx={{ mt: 1 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitOnlineVote(e)
               }}
               disabled={!onlineVoteDetails.Candidate_id}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
            >
               Submit Support Vote {'\n'} समर्थन वोट प्रस्तुत करें
            </Button>
         </Box>
         <Box>
            {uploadingOnlineVote && (
               <MyLoaderCircularGradient title={'Uploading your support vote ...'}></MyLoaderCircularGradient>
            )}
            {uploadedOnlineVote && (
               <Alert variant="outlined" severity="success">
                  Your support vote updated successfully.
               </Alert>
            )}
            {uploadingFailedOnlineVote && (
               <Alert variant="outlined" severity="error">
                  Error occured! Your support vote could not be updated.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddCandiOnlineVote.propTypes = {
   candidatesListAccepted: PropTypes.object.isRequired,
   handleAddOnlineVote: PropTypes.func.isRequired,
   uploadingOnlineVote: PropTypes.bool.isRequired,
   uploadedOnlineVote: PropTypes.bool.isRequired,
   uploadingFailedOnlineVote: PropTypes.bool.isRequired,
}

export default AddCandiOnlineVote
