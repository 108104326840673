import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, updateDoc, deleteDoc, addDoc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import axios from 'axios'

const initialState = {
   countriesList: [],
   status: 'idle',
   selectedCountryInformation: {},
}

export const countriesSlice = createSlice({
   name: 'countries',
   initialState,
   reducers: {
      updateSelectedCountry: (state, action) => {
         state.selectedCountryInformation = state.countriesList.find((item) => item.Name === action.payload)
      },
      resetCountries: () => {
         return initialState
      },
   },

   extraReducers(builder) {
      builder
         .addCase(fetchCountries.pending, (state, action) => {
            console.log('loading')
            state.status = 'loading'
         })
         .addCase(fetchCountries.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.countriesList = action.payload
            state.selectedCountryInformation = action.payload.find((item) => item.Name === 'India')
         })
         .addCase(fetchCountries.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
         .addCase(toggleForLegis.fulfilled, (state, action) => {
            // eslint-disable-next-line array-callback-return
            state.countriesList.map((country) => {
               if (country.id === action.payload) {
                  country.ForLegis = !country.ForLegis
               }
            })
         })
         .addCase(toggleForLegis.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
         .addCase(eraseCountry.fulfilled, (state, action) => {
            state.countriesList = state.countriesList.filter((country) => country.id !== action.payload)
            console.log('deleted')
         })
         .addCase(eraseCountry.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
         .addCase(addCountry.fulfilled, (state, action) => {
            state.countriesList.push(action.payload)
         })
         .addCase(addCountry.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
   },
})

export const { resetCountries, updateSelectedCountry } = countriesSlice.actions

export const selectCountries = (state) => state.countries

export default countriesSlice.reducer

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
   // const q = query(collection(db, 'Countries'), where('user_id', '==', auth.currentUser.uid))
   // const q = query(collection(db, 'Countries'), orderBy('Name', 'asc'))

   // const countryList = []

   // const querySnapshot = await getDocs(q)
   // querySnapshot.forEach((doc) => {
   //    countryList.push({ id: doc.id, ...doc.data() })
   // })

   const docRef = doc(db, 'BaseLists', 'Countries')
   const docSnap = await getDoc(docRef)
   const countryListRaw = [...docSnap.data().Countries]

   const countryList = countryListRaw.sort((a, b) => {
      if (b.Name > a.Name) {
         return -1
      } else if (b.Name < a.Name) {
         return 1
      } else {
         return 0
      }
   })
   return countryList
})

export const toggleForLegis = createAsyncThunk('countries/toggleForLegis', async (payload) => {
   const countryRef = doc(db, 'Countries', payload.id)
   await updateDoc(countryRef, {
      ForLegis: !payload.ForLegis,
   })
   return payload.id
})

export const eraseCountry = createAsyncThunk('countries/eraseCountry', async (payload) => {
   await deleteDoc(doc(db, 'Countries', payload))
   return payload
})

export const addCountry = createAsyncThunk('countries/addCountry', async (payload) => {
   const newCountry = payload
   newCountry.CrBy = auth.currentUser.uid
   newCountry.CrDt = new Date()
   const ip = await axios.get('https://ipapi.co/json')
   newCountry.CrIP = ip.data.ip
   console.log('payload: ', payload)
   console.log('newCountry: ', newCountry)
   const docRef = await addDoc(collection(db, 'Countries'), newCountry)
   newCountry.id = docRef.id
   return newCountry
})
