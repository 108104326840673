import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

function PPartyMems({ pParty, handlePPartyDetails }) {
   console.log('value from props from inside PParty Mems : ', pParty)
   return (
      <div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363, #FFFFFF)',
               pl: 1,
            }}
         >
            Party Members:
         </Typography>
      </div>
   )
}

PPartyMems.propTypes = {
   pParty: PropTypes.object.isRequired,
   handlePPartyDetails: PropTypes.func.isRequired,
}

export default PPartyMems
