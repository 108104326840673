import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import PropTypes from 'prop-types'
import { sentenceCase } from '../../Utils/Conversions/CaseConversion'

function AddText({ labelName, handleAddText }) {
   const [textDetails, setTextDetails] = useState({
      Text: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', textDetails)
   }, [textDetails])

   function handleTextDetails(event) {
      setTextDetails({ ...textDetails, [event.target.name]: event.target.value })
   }

   function handleSubmitText(e) {
      e.preventDefault()

      if (textDetails.Text === '') {
         alert('Please add some text.')
         return
      } else {
         textDetails.Text = sentenceCase(textDetails.Text.trim())
      }
      handleAddText(textDetails)

      reset()
   }

   function reset() {
      setTextDetails({
         Text: '',
      })
   }

   return (
      <Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleTextDetails}
               value={textDetails.Text}
               name="Text"
               id="text"
               label={labelName}
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div className={myStyles.text_align_center}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               sx={{ my: 1 }}
               onClick={(e) => {
                  handleSubmitText(e)
               }}
               disabled={textDetails.Text.length < 3}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddText.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleAddText: PropTypes.func.isRequired,
}

export default AddText
