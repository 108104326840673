import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Box,
   Button,
   FormControl,
   FormControlLabel,
   InputLabel,
   MenuItem,
   Modal,
   Radio,
   RadioGroup,
   Select,
   Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddLegisMem from './AddLegisMem'
import { auth, db } from '../../../FirebaseConfig'
import { addDoc, arrayUnion, collection, doc, updateDoc } from 'firebase/firestore'
// import { useParams } from 'react-router-dom'

function LegisMems({ legis, membersListRoot, handleMembersListRootUpdate }) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   // const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [lastMember, setLastMember] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         // const sortedMembers = [...legis.Members].sort((a, b) => {
         //    if (b.Name > a.Name) {
         //       return -1
         //    } else if (b.Name < a.Name) {
         //       return 1
         //    } else {
         //       return 0
         //    }
         // })
         // setMembersListRoot(sortedMembers)
         // setMembersList(sortedMembers)
         // setUploadingMembersList(false)
         //
         setFirstLoadStatus('success')
      }
   }, [])

   // related to form filling and submission

   const [openModalAddMember, setOpenModalAddMember] = useState(false)

   const handleOpenModalAddMember = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddMember(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalAddMember = () => setOpenModalAddMember(false)

   const handleAddMember = async (constiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               constiDetails.Name.length >= 3 &&
               constiDetails.IsNominated !== '' &&
               constiDetails.MembertuencyNumber !== ''
            ) {
               setUploading(true)

               constiDetails.CrBy = auth.currentUser.uid
               constiDetails.CrDt = new Date()
               constiDetails.CrIP = ip.data.ip
               constiDetails.NameOld = [
                  {
                     Name: constiDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (constiDetails.NameHindi !== '') {
                  constiDetails.NameHindiOld = [
                     { Name: constiDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (constiDetails.NameLocal !== '') {
                  constiDetails.NameLocalOld = [
                     {
                        Name: constiDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               console.log('constiDetails before entry: ', constiDetails)
               setLastMember(constiDetails.Name)
               try {
                  const docRef = await addDoc(collection(db, 'LegisMembers'), constiDetails)
                  constiDetails.Membertuency_id = docRef.id

                  const record = {
                     Membertuency_id: docRef.id,
                     Legislature_id: legis.id,
                     LegislatureName: legis.Name,
                     MembertuencyNumber: constiDetails.MembertuencyNumber,
                     Name: constiDetails.Name,
                     NameHindi: constiDetails.NameHindi,
                     NameLocal: constiDetails.NameLocal,
                     NameAlt: constiDetails.NameAlt,
                     StateName: constiDetails.StateName,
                     State_id: constiDetails.State_id,
                     DivisionName: constiDetails.DivisionName,
                     Division_id: constiDetails.Division_id,
                     DistrictName: constiDetails.DistrictName,
                     District_id: constiDetails.District_id,
                     SubDistrict_id: constiDetails.SubDistrict_id,
                     SubDistrictName: constiDetails.SubDistrictName,
                     Block_id: constiDetails.Block_id,
                     BlockName: constiDetails.BlockName,
                     Village_id: constiDetails.Village_id,
                     VillageName: constiDetails.VillageName,
                     ParentMember_id: constiDetails.ParentMember_id,
                     ParentMemberName: constiDetails.ParentMemberName,
                     IsNominated: constiDetails.IsNominated,
                     Strata: constiDetails.Strata,
                  }
                  console.log('record: ', record)
                  console.log(legis.id)

                  const docRefUpdate = doc(db, 'Legislatures', legis.id)
                  await updateDoc(docRefUpdate, {
                     Members: arrayUnion(record),
                  })

                  // setMembersList([...constisList, constiDetails])
                  // setMembersListRoot([...constisListRoot, constiDetails])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding legislature: ', error.message)
                  console.log(error)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ px: 1, minHeight: 600 }}>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363, #FFFFFF)',
               pl: 1,
            }}
         >
            Elected Members / <strong>निर्वाचित सदस्य</strong>:
         </Typography>
         <Box>
            <Box id="addbutton" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
               {(isDataEditor || isSuper) && (
                  <Button
                     onClick={(e) => {
                        handleOpenModalAddMember(e)
                     }}
                     size="small"
                     variant="outlined"
                     sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0, mr: 1 }}
                  >
                     Add
                  </Button>
               )}
               <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, ml: 'auto' }}>
                  <Box sx={{ color: '#795548', fontSize: 15 }}>Sort by:</Box>
                  <Box>
                     <FormControl
                        sx={{
                           p: 0,
                        }}
                     >
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="SortField"
                           value={''}
                           // style={{ display: 'flex', gap: '2rem' }}
                           onChange={(e) => {
                              // handleSortField(e)
                           }}
                           sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: 1,
                              py: 0,
                              '& .MuiSvgIcon-root': {
                                 fontSize: 15,
                              },
                              '& .MuiButtonBase-root': {
                                 py: 0,
                              },
                              '& MuiFormControlLabel-label': {
                                 fontSize: 13,
                                 lineHeight: 16,
                                 py: 0,
                              },
                           }}
                        >
                           <FormControlLabel
                              value="Name"
                              control={<Radio size="small" />}
                              label="Name"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="MembertuencyNumber"
                              control={<Radio size="small" />}
                              label="Membertuency number"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                        </RadioGroup>
                     </FormControl>
                  </Box>
               </Box>
            </Box>
            <Box id="sort" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
               {legis.Strata === 'Central' && (
                  <>
                     <Box sx={{ display: 'inline', ml: 'auto', color: '#795548' }}>filter by:</Box>
                     <Box sx={{ display: 'inline', padding: 0 }}>
                        {legis.Strata === 'Central' && (
                           <FormControl sx={{ m: 1, width: '30ch' }} size="small">
                              <InputLabel>State / UT</InputLabel>
                              <Select value={''} label="State / UT" onChange={''} sx={{ backgroundColor: '#ffffff' }}>
                                 <MenuItem value="">
                                    <em>None</em>
                                 </MenuItem>
                                 {/* {statesList &&
                                    statesList.map((item) => (
                                       <MenuItem key={item.State_id} value={item.State_id}>
                                          {item.Name}
                                       </MenuItem>
                                    ))} */}
                              </Select>
                           </FormControl>
                        )}
                     </Box>
                  </>
               )}
            </Box>
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalAddMember}
               onClose={handleCloseModalAddMember}
               aria-labelledby="modal-modal-title-addMember"
               aria-describedby="modal-modal-description-addMember"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addMember"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a constituency
                  </Typography>
                  <AddLegisMem
                     lastMember={lastMember}
                     uploading={uploading}
                     uploaded={uploaded}
                     uploadingFailed={uploadingFailed}
                     handleAddMember={handleAddMember}
                  ></AddLegisMem>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

LegisMems.propTypes = {
   legis: PropTypes.object.isRequired,
   membersListRoot: PropTypes.array.isRequired,
   handleMembersListRootUpdate: PropTypes.func.isRequired,
}

export default LegisMems
