import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { auth, db, dbStorage } from '../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import { v4 } from 'uuid'
import moment from 'moment'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../redux/reducers/users-slice'
import { Box, Divider, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import myStyles from './ElecManifesto.module.css'
import { styleModalBox, styleModalTypography } from '../../Utils/MUITheme/MUITheme'
import AddText from '../../Components/Edits/AddText'
import AddEmail from '../../Components/Edits/AddEmail'
import AddWeblink from '../../Components/Edits/AddWeblink'
import AddImage from '../../Components/Edits/AddImage'
import AddDate from '../../Components/Edits/AddDate'

function ElecManifestoBasic({ manifesto }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [record, setRecord] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setRecord({
            Manifesto_id: manifesto.id,
            Name: manifesto.Name,
            NameHindi: manifesto.NameHindi,
            NameLocal: manifesto.NameLocal,
            IssuedByParty_id: manifesto.IssuedByParty_id,
            IssuedByPartyName: manifesto.IssuedByPartyName,
            IssuedByPartyNameHindi: manifesto.IssuedByPartyNameHindi,
            IssuedByPartyNameLocal: manifesto.IssuedByPartyNameLocal,
            IssuedByPartyAbbreviation: manifesto.IssuedByPartyAbbreviation,
            IssuedByPartyFlag: manifesto.IssuedByPartyFlag,
            IssuedByAlliance_id: manifesto.IssuedByAlliance_id,
            IssuedByAllianceName: manifesto.IssuedByAllianceName,
            IssuedByAllianceNameHindi: manifesto.IssuedByAllianceNameHindi,
            IssuedByAllianceNameLocal: manifesto.IssuedByAllianceNameLocal,
            IssuedByAllianceAbbreviation: manifesto.IssuedByAllianceAbbreviation,
            IssuedByAllianceLogo: manifesto.IssuedByAllianceLogo,
            Election_id: manifesto.Election_id,
            ElectionName: manifesto.ElectionName,
            ElectionNameHindi: manifesto.ElectionNameHindi,
            ElectionNameLocal: manifesto.ElectionNameLocal,
            Alliances: manifesto.Alliances,
            DateReleased: manifesto.DateReleased,
            Image: '',
         })

         setFirstLoadStatus('success')

         console.log('ElecCandis - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (record !== '') {
         console.log('record', record)
      }
   }, [record])

   const [sourceImage, setSourceImage] = useState(manifesto.Image)

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)
            const electionRef = doc(db, 'Elections', manifesto.Election_id)
            const pPartyRef = doc(db, 'PoliticalParties', manifesto.IssuedByParty_id)
            const pPAllianceRef = doc(db, 'PPAlliances', manifesto.IssuedByAlliance_id)

            await updateDoc(manifestoRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            // update election
            await updateDoc(electionRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(electionRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update party
            await updateDoc(pPartyRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPartyRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update alliance
            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update the name display
            manifesto.Name = textDetails.Text
            setRecord({ ...record, Name: textDetails.Text })

            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)
            const electionRef = doc(db, 'Elections', manifesto.Election_id)
            const pPartyRef = doc(db, 'PoliticalParties', manifesto.IssuedByParty_id)
            const pPAllianceRef = doc(db, 'PPAlliances', manifesto.IssuedByAlliance_id)

            await updateDoc(manifestoRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            // update election
            await updateDoc(electionRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(electionRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update party
            await updateDoc(pPartyRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPartyRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update alliance
            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update the nameHindi display
            manifesto.NameHindi = textDetails.Text
            setRecord({ ...record, NameHindi: textDetails.Text })

            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)
            const electionRef = doc(db, 'Elections', manifesto.Election_id)
            const pPartyRef = doc(db, 'PoliticalParties', manifesto.IssuedByParty_id)
            const pPAllianceRef = doc(db, 'PPAlliances', manifesto.IssuedByAlliance_id)

            await updateDoc(manifestoRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            // update election
            await updateDoc(electionRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(electionRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update party
            await updateDoc(pPartyRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPartyRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update alliance
            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayRemove(record),
            })

            await updateDoc(pPAllianceRef, {
               Manifestoes: arrayUnion(recordNew),
            })

            // update the nameLocal display
            manifesto.NameLocal = textDetails.Text
            setRecord({ ...record, NameLocal: textDetails.Text })

            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DateReleased modal

   const [openModalDateReleased, setOpenModalDateReleased] = useState(false)

   const handleOpenModalDateReleased = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalDateReleased(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalDateReleased = () => setOpenModalDateReleased(false)

   const handleAddDateReleased = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.Date !== '') {
               const dateReleasedForOld = {
                  DateReleased: dateDetails.Date,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)
               const electionRef = doc(db, 'Elections', manifesto.Election_id)
               const pPartyRef = doc(db, 'PoliticalParties', manifesto.IssuedByParty_id)
               const pPAllianceRef = doc(db, 'PPAlliances', manifesto.IssuedByAlliance_id)

               await updateDoc(manifestoRef, {
                  Dob: dateDetails.Date,
                  DobOld: arrayUnion(dateReleasedForOld),
               })

               const recordNew = { ...record, DateReleased: dateDetails.Date }

               // update election
               await updateDoc(electionRef, {
                  Manifestoes: arrayRemove(record),
               })

               await updateDoc(electionRef, {
                  Manifestoes: arrayUnion(recordNew),
               })

               // update party
               await updateDoc(pPartyRef, {
                  Manifestoes: arrayRemove(record),
               })

               await updateDoc(pPartyRef, {
                  Manifestoes: arrayUnion(recordNew),
               })

               // update alliance
               await updateDoc(pPAllianceRef, {
                  Manifestoes: arrayRemove(record),
               })

               await updateDoc(pPAllianceRef, {
                  Manifestoes: arrayUnion(recordNew),
               })

               // update the DateReleased display
               manifesto.DateReleased = dateDetails.Date
               setRecord({ ...record, Dob: dateDetails.Date })

               setOpenModalDateReleased(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //

            const imageRef = ref(
               dbStorage,
               `ElectionManifestoes/${manifesto.Election_id}/${imageDetails.Image.name + v4()}`,
            )
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, Image: url }

                  const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)
                  const electionRef = doc(db, 'Elections', manifesto.Election_id)
                  const pPartyRef = doc(db, 'PoliticalParties', manifesto.IssuedByParty_id)
                  const pPAllianceRef = doc(db, 'PPAlliances', manifesto.IssuedByAlliance_id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     await updateDoc(manifestoRef, {
                        Image: url,
                        ImageOld: arrayUnion(forOld),
                     })

                     // update election
                     await updateDoc(electionRef, {
                        Manifestoes: arrayRemove(record),
                     })

                     await updateDoc(electionRef, {
                        Manifestoes: arrayUnion(recordNew),
                     })

                     // update party
                     await updateDoc(pPartyRef, {
                        Manifestoes: arrayRemove(record),
                     })

                     await updateDoc(pPartyRef, {
                        Manifestoes: arrayUnion(recordNew),
                     })

                     // update alliance
                     await updateDoc(pPAllianceRef, {
                        Manifestoes: arrayRemove(record),
                     })

                     await updateDoc(pPAllianceRef, {
                        Manifestoes: arrayUnion(recordNew),
                     })

                     setRecord({ ...record, Image: url })
                     setSourceImage(url)
                  } else {
                     await updateDoc(manifestoRef, {
                        ImageOld: arrayUnion(forOld),
                     })
                  }
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)

            await updateDoc(manifestoRef, {
               WebLinks: arrayUnion(weblinkDetails.Weblink),
               WebLinksOld: arrayUnion(weblinkDetails),
            })

            // update the website display
            manifesto.WebLinks.push(weblinkDetails.Weblink)

            // close modal
            // setOpenModalWebsite(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip

            const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)

            await updateDoc(manifestoRef, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the website display
            manifesto.Emails.push(emailDetails.Email)

            // close modal
            setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Box sx={{ textAlign: 'center' }}>
                  {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX2} /> : null}
               </Box>
               <Table>
                  <tbody>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{manifesto.Name}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalName}
                                    onClose={handleCloseModalName}
                                    aria-labelledby="modal-modal-title-name"
                                    aria-describedby="modal-modal-description-name"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-name"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name
                                       </Typography>
                                       <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{manifesto.NameHindi}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameHindi}
                                    onClose={handleCloseModalNameHindi}
                                    aria-labelledby="modal-modal-title-Hindiname"
                                    aria-describedby="modal-modal-description-Hindiname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-hindiname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in hindi
                                       </Typography>
                                       <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name local:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{manifesto.NameLocal}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameLocal}
                                    onClose={handleCloseModalNameLocal}
                                    aria-labelledby="modal-modal-title-localname"
                                    aria-describedby="modal-modal-description-localname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-localname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in local language
                                       </Typography>
                                       <AddText
                                          handleAddText={handleAddNameLocal}
                                          labelName="Name (in local language)"
                                       ></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Release date {'\n'} प्रकाशन तिथि:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {manifesto.DateReleased !== '' && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                       <Box
                                          sx={{
                                             mr: 1,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          Notified:
                                       </Box>
                                       <Box>{moment(manifesto.DateReleased.toDate()).format('Do MMMM YYYY')}</Box>
                                    </Box>
                                 )}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDateReleased"
                                    onClick={(e) => {
                                       handleOpenModalDateReleased(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalDateReleased}
                                 onClose={handleCloseModalDateReleased}
                                 aria-labelledby="modal-modal-title-dob"
                                 aria-describedby="modal-modal-description-dob"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-dob"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Date Of Birth
                                    </Typography>
                                    <AddDate
                                       labelName="Date of Birth / जन्म तिथि"
                                       handleAddDate={handleAddDateReleased}
                                    ></AddDate>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Image:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editImage"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalImage}
                                    onClose={handleCloseModalImage}
                                    aria-labelledby="modal-modal-title-logo"
                                    aria-describedby="modal-modal-description-logo"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-logo"
                                          variant="h6"
                                          component="h2"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add image of Candidate
                                       </Typography>
                                       <AddImage handleAddImage={handleAddImage}></AddImage>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head" sx={{ whiteSpace: 'pre-line' }}>
                           Released by
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {manifesto.IssuedByAlliance_id !== '' && (
                                 <>
                                    <Box>
                                       {manifesto.IssuedByAllianceLogo && (
                                          <img src={manifesto.IssuedByAllianceLogo} className={myStyles.imgX} />
                                       )}
                                    </Box>
                                    <Box
                                       sx={{
                                          ml: 1,
                                          whiteSpace: 'pre-line',
                                       }}
                                    >
                                       {manifesto.IssuedByAllianceName} {'\n'} {manifesto.IssuedByAllianceNameHindi}{' '}
                                       {'\n'} {manifesto.IssuedByAllianceNameLocal}
                                    </Box>
                                 </>
                              )}
                              {manifesto.IssuedByParty_id !== '' && (
                                 <>
                                    <Box>
                                       {manifesto.IssuedByPartyFlag && (
                                          <img src={manifesto.IssuedByPartyFlag} className={myStyles.imgX} />
                                       )}
                                    </Box>
                                    <Box
                                       sx={{
                                          ml: 1,
                                          whiteSpace: 'pre-line',
                                       }}
                                    >
                                       {manifesto.IssuedByPartyName} {'\n'} {manifesto.IssuedByPartyNameHindi} {'\n'}{' '}
                                       {manifesto.IssuedByPartyNameLocal}
                                    </Box>
                                 </>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     {manifesto.IssuedByParty_id !== '' && (
                        <TableRow>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={myStyles.cell_style_left}
                              variant="head"
                           >
                              Silent / Tacit Support {'\n'} मौन / परोक्ष समर्थन:
                           </TableCell>
                           <TableCell sx={{ wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {manifesto.Alliances.map((itemAll, index) => (
                                       <>
                                          {index + 1}.
                                          <Link
                                             key={itemAll.Alliance_id}
                                             href={`/alliance/${itemAll.Name}/${itemAll.Alliance_id}`}
                                             sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                             rel="noreferrer"
                                             target="_blank"
                                          >
                                             {itemAll.Name} <strong>({itemAll.Abbreviation})</strong>
                                          </Link>
                                          <br />
                                          {itemAll.NameLocal !== '' && (
                                             <Link
                                                key={itemAll.Alliance_id}
                                                href={`/alliance/${itemAll.Name}/${itemAll.Alliance_id}`}
                                                sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                {itemAll.NameLocal}
                                             </Link>
                                          )}
                                          {itemAll.NameLocal === '' && (
                                             <Link
                                                key={itemAll.Alliance_id}
                                                href={`/alliance/${itemAll.Name}/${itemAll.Alliance_id}`}
                                                sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                {itemAll.NameHindi}
                                             </Link>
                                          )}
                                          <br />
                                          <Box sx={{ pl: 5 }}>
                                             {itemAll.Parties.map((itemP, index) => (
                                                <Box key={itemP.Party_id}>
                                                   {index + 1}.
                                                   <Link
                                                      href={`/political-party/${itemP.PartyName}/${itemP.Party_id}`}
                                                      sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {itemP.PartyName}{' '}
                                                      <strong style={{ color: '#000000' }}>
                                                         ({itemP.PartyAbbreviation})
                                                      </strong>
                                                   </Link>
                                                   {itemP.PartyNameLocal !== '' && (
                                                      <>
                                                         <br />
                                                         <Link
                                                            href={`/political-party/${itemP.PartyName}/${itemP.Party_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemP.PartyNameLocal}
                                                         </Link>
                                                      </>
                                                   )}
                                                   {itemP.PartyNameLocal === '' && (
                                                      <>
                                                         <br />
                                                         <Link
                                                            href={`/political-party/${itemP.PartyName}/${itemP.Party_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemP.PartyNameHindi}
                                                         </Link>
                                                      </>
                                                   )}
                                                   <Divider />
                                                </Box>
                                             ))}
                                          </Box>
                                       </>
                                    ))}
                                 </Box>

                                 {/* {(isDataEditor || isSuper) && (
                           <IconButton
                              edge="end"
                              aria-label="editAddress"
                              onClick={(e) => {
                                 handleOpenModalAddress(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )} */}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}

                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Web Links {'\n'} वेब लिंक:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>
                                 {manifesto.WebLinks &&
                                    manifesto.WebLinks.length > 0 &&
                                    manifesto.WebLinks.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <Link
                                                   underline="hover"
                                                   href={item}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                      <FacebookIcon />
                                                   )}
                                                   {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                      <InstagramIcon />
                                                   )}
                                                   {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                      <LinkedInIcon />
                                                   )}
                                                   {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                </Link>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <Link
                                                underline="hover"
                                                href={`//${item}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                   <FacebookIcon />
                                                ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                   <InstagramIcon />
                                                ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                   <LinkedInIcon />
                                                ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                   <XIcon sx={{ color: '#000000' }} />
                                                ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                   <YouTubeIcon />
                                                ) : (
                                                   item
                                                )}
                                             </Link>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalWebsite}
                                 onClose={handleCloseModalWebsite}
                                 aria-labelledby="modal-modal-title-website"
                                 aria-describedby="modal-modal-description-website"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-website"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a web link
                                    </Typography>
                                    <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Emails {'\n'} ईमेल:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {manifesto.Emails &&
                                    manifesto.Emails.length > 0 &&
                                    manifesto.Emails.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editEmail"
                                    onClick={(e) => {
                                       handleOpenModalEmail(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalEmail}
                                 onClose={handleCloseModalEmail}
                                 aria-labelledby="modal-modal-title-email"
                                 aria-describedby="modal-modal-description-email"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-email"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add an email
                                    </Typography>
                                    <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

ElecManifestoBasic.propTypes = {
   manifesto: PropTypes.object.isRequired,
}

export default ElecManifestoBasic
