import {
   Avatar,
   Box,
   Button,
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../../redux/reducers/users-slice'
import AddPPartiesAlliance from './AddPPartiesAlliance'
import { auth, db } from '../../../FirebaseConfig'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete'
// import EditIcon from '@mui/icons-material/Edit'

function PPartiesAlliances({ alliancesListRoot, handleAlliancesListRootUpdate }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('PPAlliances - first use effect entered')

      if (user.currentUser !== null) {
         console.log('PPAlliances - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('PPAlliances - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // style for modal
   const styleModalBox = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: '#ffffff',
      border: '0',
      borderRadius: 1,
      boxShadow: 24,
      pb: 1,
      mx: 1,

      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
         width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
         background: '#f1f1f1',
         borderRadius: 5,
         // margin: 5,
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: '#000000',
         borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb:hover': {
         background: '#555',
      },

      maxHeight: '90%',
      overflowY: 'auto',

      // background: 'linear-gradient(to top, #cfd1a8 0%, #f9fff3 100%)',
   }

   const styleModalTypography = {
      fontSize: '14px',
      color: '#ffffff',
      backgroundImage: 'linear-gradient(to right, #ffffff, #eb9191, #797580, #797580, #eb9191, #ffffff)',
      mt: 1,
      mb: 2,
   }

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [alliancesListRootLocal, setAlliancesListRootLocal] = useState([])
   const [alliancesListActive, setAlliancesListActive] = useState([])
   const [alliancesListInactive, setAlliancesListInactive] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         setAlliancesListRootLocal(alliancesListRoot)

         const listActive = alliancesListRoot.filter((item) => item.IsActive === true)
         let sortedListActive = []
         sortedListActive = [...listActive].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setAlliancesListActive(sortedListActive)

         const listInactive = alliancesListRoot.filter((item) => item.IsActive === false)
         let sortedListInactive = []
         sortedListInactive = [...listInactive].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setAlliancesListInactive(sortedListInactive)

         setFirstLoadStatus('success')
         console.log('PPAlliances - first use effect blank')
      }
   }, [])

   const [openModalAddAlliance, setOpenModalAddAlliance] = useState(false)

   const handleOpenModalAddAlliance = async (e) => {
      e.preventDefault()

      setOpenModalAddAlliance(true)
   }

   const handleCloseModalAddAlliance = () => setOpenModalAddAlliance(false)

   const [lastAlliance, setLastAlliance] = useState('')
   const [uploadingAlliance, setUploadingAlliance] = useState(false)
   const [uploadedAlliance, setUploadedAlliance] = useState(false)
   const [uploadingFailedAlliance, setUploadingFailedAlliance] = useState(false)

   const handleAddAlliance = async (allianceDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (allianceDetails.Name.length > 3 && allianceDetails.Abbreviation.length > 1) {
               setUploadingAlliance(true)

               allianceDetails.CrBy = auth.currentUser.uid
               allianceDetails.CrDt = new Date()
               allianceDetails.CrIP = ip.data.ip

               allianceDetails.Country_id = 'lsTDGRF8XHWnR3VjVuB4'
               allianceDetails.CountryName = 'India'
               allianceDetails.CountryNameHindi = 'Bhaarat'

               allianceDetails.Name = sentenceCase(allianceDetails.Name).trim()
               allianceDetails.NameHindi = allianceDetails.NameHindi.trim()
               allianceDetails.NameLocal = allianceDetails.NameLocal.trim()
               allianceDetails.Abbreviation = allianceDetails.Abbreviation.trim()

               allianceDetails.NameOld = [
                  {
                     Name: sentenceCase(allianceDetails.Name).trim(),
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (allianceDetails.NameHindi !== '') {
                  allianceDetails.NameHindiOld = [
                     {
                        Name: allianceDetails.NameHindi.trim(),
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               if (allianceDetails.NameLocal !== '') {
                  allianceDetails.NameLocalOld = [
                     {
                        Name: allianceDetails.NameLocal.trim(),
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }

               console.log('alliance Details 1: ', allianceDetails)

               setLastAlliance(allianceDetails.Name)
               console.log('alliance Details 2: ', allianceDetails, ' uploading: ', uploadingAlliance)

               const record = {
                  Alliance_id: '',
                  Name: allianceDetails.Name,
                  NameHindi: allianceDetails.NameHindi,
                  NameLocal: allianceDetails.NameLocal,
                  Abbreviation: allianceDetails.Abbreviation,
                  IsActive: allianceDetails.IsActive,
                  Party_id_s: allianceDetails.Party_id_s,
                  FoundedDate: allianceDetails.FoundedDate,
                  Logo: allianceDetails.Logo,
               }

               try {
                  let recordNew = {}

                  const docRef = await addDoc(collection(db, 'PPAlliances'), allianceDetails)
                  allianceDetails.id = docRef.id

                  recordNew = { ...record, Alliance_id: docRef.id }
                  console.log('record new : ', recordNew)

                  const docRefUpdate = doc(db, 'BaseLists', 'PPAlliancesBase')
                  console.log('core list update stage 2')
                  await updateDoc(docRefUpdate, {
                     Alliances: arrayUnion(recordNew),
                  })

                  setAlliancesListActive([...alliancesListActive, recordNew])
                  setAlliancesListRootLocal([...alliancesListRootLocal, recordNew])
                  handleAlliancesListRootUpdate([...alliancesListRootLocal, recordNew])
                  setLastAlliance(allianceDetails.Name)

                  setUploadingAlliance(false)
                  setUploadedAlliance(true)
                  setUploadingFailedAlliance(false)
               } catch (error) {
                  alert('Error adding alliance: ', error.message)
                  console.log('Error adding alliance: ', error)
                  setUploadingAlliance(false)
                  setUploadedAlliance(false)
                  setUploadingFailedAlliance(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (
      e,
      thisAllianceId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisAbbreviation,
      thisIsActive,
      thisPartyIDs,
      thisFoundedDate,
      thisLogo,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this alliance?')) {
               try {
                  //
                  await deleteDoc(doc(db, 'PPAlliances', thisAllianceId))

                  const record = {
                     Alliance_id: thisAllianceId,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     Abbreviation: thisAbbreviation,
                     IsActive: thisIsActive,
                     Party_id_s: thisPartyIDs,
                     FoundedDate: thisFoundedDate,
                     Logo: thisLogo,
                  }
                  console.log('value to remove: ', record)
                  const docRefUpdate = doc(db, 'BaseLists', 'PPAlliancesBase')
                  await updateDoc(docRefUpdate, {
                     Alliances: arrayRemove(record),
                  })

                  setAlliancesListActive(alliancesListActive.filter((item) => item.Alliance_id !== thisAllianceId))
                  setAlliancesListInactive(alliancesListInactive.filter((item) => item.Alliance_id !== thisAllianceId))

                  setAlliancesListRootLocal(
                     alliancesListRootLocal.filter((item) => item.Alliance_id !== thisAllianceId),
                  )
                  handleAlliancesListRootUpdate(
                     alliancesListRootLocal.filter((item) => item.Alliance_id !== thisAllianceId),
                  )
               } catch (error) {
                  alert('Error deleting alliance')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   return (
      <Box>
         {(isSuper || isDataEditor) && (
            <Box>
               <Button
                  sx={{ py: 0, px: 1, minWidth: 0 }}
                  onClick={(e) => {
                     handleOpenModalAddAlliance(e)
                  }}
               >
                  Add Alliances
               </Button>
               <Modal
                  open={openModalAddAlliance}
                  onClose={handleCloseModalAddAlliance}
                  aria-labelledby="modal-modal-title-addAlliance"
                  aria-describedby="modal-modal-description-addAlliance"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addAlliance"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add an alliance
                     </Typography>
                     <AddPPartiesAlliance
                        lastAlliance={lastAlliance}
                        uploadingAlliance={uploadingAlliance}
                        uploadedAlliance={uploadedAlliance}
                        uploadingFailedAlliance={uploadingFailedAlliance}
                        handleAddAlliance={handleAddAlliance}
                     ></AddPPartiesAlliance>
                  </Box>
               </Modal>
            </Box>
         )}
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               color: '#ffffff',
               backgroundImage: 'linear-gradient(to right, #4caf50, #4caf50, #ffffff)',
               fontWeight: 700,
            }}
         >
            Active Alliances / सक्रिय गठबंधन:
         </Box>
         <Box>
            <List dense>
               {alliancesListActive &&
                  alliancesListActive.length > 0 &&
                  alliancesListActive.map((item, index) => (
                     <div key={item.Alliance_id}>
                        <ListItem
                           sx={{ pl: 0, ml: 0 }}
                           key={item.Candidate_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDelete(
                                             e,
                                             item.Alliance_id,
                                             item.Name,
                                             item.NameHindi,
                                             item.NameLocal,
                                             item.Abbreviation,
                                             item.IsActive,
                                             item.Party_id_s,
                                             item.FoundedDate,
                                             item.Logo,
                                          )
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <Link
                              href={`/alliance/${item.Name}/${item.Alliance_id}`}
                              sx={{ textDecoration: 'none !important' }}
                              target="_blank"
                              rel="noopener"
                           >
                              <ListItemAvatar>
                                 <Avatar src={item.Logo}></Avatar>
                              </ListItemAvatar>
                           </Link>

                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       underline="hover"
                                       href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                       sx={{ textDecoration: 'none !important', mr: 1 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name}</strong>
                                    </Link>
                                    <strong>{item.Abbreviation}</strong>
                                    {item.NameLocal && (
                                       <>
                                          <br />
                                          <Link
                                             href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             {item.NameLocal}
                                          </Link>
                                       </>
                                    )}
                                    <br />
                                    <Link
                                       underline="none"
                                       href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                       color="warning"
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                       {'  '}
                                    </Link>
                                 </>
                              }
                              component={'span'}
                              secondary={<></>}
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
         </Box>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #f44336, #f44336, #ffffff)',
               color: '#ffffff',
               fontWeight: 700,
            }}
         >
            Inactive Alliances / निष्क्रिय गठबंधन:
         </Box>
         <Box>
            <List dense>
               {alliancesListInactive &&
                  alliancesListInactive.length > 0 &&
                  alliancesListInactive.map((item, index) => (
                     <div key={item.Alliance_id}>
                        <ListItem
                           sx={{ pl: 0, ml: 0 }}
                           key={item.Candidate_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDelete(
                                             e,
                                             item.Alliance_id,
                                             item.Name,
                                             item.NameHindi,
                                             item.NameLocal,
                                             item.Abbreviation,
                                             item.IsActive,
                                             item.Party_id_s,
                                             item.FoundedDate,
                                             item.Logo,
                                          )
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <Link
                              href={`/alliance/${item.Name}/${item.Alliance_id}`}
                              sx={{ textDecoration: 'none !important' }}
                              target="_blank"
                              rel="noopener"
                           >
                              <ListItemAvatar>
                                 {item.Abbreviation ? <Avatar>{item.Abbreviation}</Avatar> : <Avatar />}
                              </ListItemAvatar>
                           </Link>

                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       underline="hover"
                                       href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name}</strong>
                                    </Link>{' '}
                                    -{' '}
                                    {item.NameLocal && (
                                       <Link
                                          href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                          target="_blank"
                                          rel="noopener"
                                       >
                                          {item.NameLocal}
                                       </Link>
                                    )}
                                    {'  '}-{' '}
                                    <Link
                                       underline="none"
                                       href={`/alliance/${item.Name}/${item.Alliance_id}`}
                                       color="warning"
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                       {'  '}
                                    </Link>
                                    ({item.Age}y)
                                 </>
                              }
                              component={'span'}
                              secondary={
                                 <>
                                    <>
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {item.Abbreviation}
                                       </Typography>
                                    </>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

PPartiesAlliances.propTypes = {
   alliancesListRoot: PropTypes.array.isRequired,
   handleAlliancesListRootUpdate: PropTypes.func.isRequired,
}

export default PPartiesAlliances
