import React, { useEffect, useRef, useState } from 'react'

import styles from './PParties.module.css'
import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { selectUsers } from '../../../redux/reducers/users-slice'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDocs,
   increment,
   orderBy,
   query,
   updateDoc,
   where,
} from 'firebase/firestore'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import CopyToClipboard from 'react-copy-to-clipboard'
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

function PPartiesAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // use state part
   const [selectedCountry, setSelectedCountry] = useState('')
   const [selectedCountryText, setSelectedCountryText] = useState('')
   const [levels, setLevels] = useState('')
   const [selectedLevel, setSelectedLevel] = useState('')
   const [selectedLevelText, setSelectedLevelText] = useState('')
   const [showLevel, setShowLevel] = useState(false)
   const [showForm, setShowForm] = useState(false)
   const [showPartiesList, setShowPartiesList] = useState(false)
   const [partiesList, setPartiesList] = useState('')

   const [indexOfCopy, setIndexOfCopy] = useState('') // The text you want to copy
   const [copyStatus, setCopyStatus] = useState(false) // To indicate if the text was copied

   const onCopyText = (index) => {
      setCopyStatus(true)
      setIndexOfCopy(index)
      setTimeout(() => setCopyStatus(false), 2000) // Reset status after 2 seconds
   }

   const addressDetails = {
      ValueRadio: '',
      State_id: '',
      StateName: '',
      District_id: '',
      DistrictName: '',
      PinCode: '',
      Address: '',
      RecordedDate: '',
   }

   const [partyDetails, setPartyDetails] = useState({
      Name: '',
      NameOld: [],
      NameAlt: [],
      NameAltOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      Abbreviation: '',
      AbbreviationOld: [],
      ECIStatus: '',
      ECIStatusOld: [],
      ECIStatusDate: '',
      Country_id: '',
      Level: '',
      State_ids: [],
      StateNames: [],
      Flag: null,
      FlagOld: [],
      Symbol: null,
      SymbolOld: [],
      Logo: '',
      LogoOld: [],
      Headquarter: addressDetails,
      HeadquarterOld: [],
      Newspapers: [],
      NewspapersOld: [],
      Thinktanks: [],
      ThinktanksOld: [],
      Websites: [],
      WebsitesOld: [],
      Active: true,
      ActiveOld: [],
      CrBy: '',
      CrDt: '',
      CrIP: '',
      CrDtSys: '',
   })

   useEffect(() => {
      console.log('Party details on change of its value: ', partyDetails)
   }, [partyDetails])

   useEffect(() => {
      fetchLevels()
   }, [])

   // related to countries
   const countries = useSelector(selectCountries).countriesList

   const handleCountryChange = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(e.target.value)
            setPartyDetails({ ...partyDetails, Country_id: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      setShowForm(false)
      setShowPartiesList(false)
      if (selectedCountry) {
         const countryText = countries.find((item) => item.id === selectedCountry)
         setSelectedCountryText(countryText.Name)
         setShowLevel(true)
         setSelectedLevel('')
      } else {
         setShowLevel(false)
      }
   }, [selectedCountry])

   // related to levels

   const fetchLevels = async () => {
      try {
         const q = query(collection(db, 'PartyLevels'), orderBy('Priority', 'asc'))
         const querySnapshot = await getDocs(q)
         const levelsFetched = []
         querySnapshot.forEach((doc) => {
            levelsFetched.push({ id: doc.id, ...doc.data() })
         })
         setLevels(levelsFetched)
         console.log('Levels fetched by country: ', levelsFetched)
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleLevelChange = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedLevel(e.target.value)
            setPartyDetails({ ...partyDetails, Level: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      console.log('selected level: ', selectedLevel)
      console.log('selected country: ', selectedCountry)
      if (selectedLevel) {
         setShowForm(true)
         fetchPartiesListByLevel()
         setShowPartiesList(true)
         const levelText = levels.find((item) => item.id === selectedLevel)
         setSelectedLevelText(levelText.Name)
      } else {
         setShowForm(false)
         setShowPartiesList(false)
      }
   }, [selectedLevel])

   // related to parties list

   const fetchPartiesListByLevel = async () => {
      try {
         const q = query(
            collection(db, 'PoliticalParties'),
            where('Country_id', '==', selectedCountry),
            where('ECIStatus', '==', selectedLevel),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const partiesListFetched = []
         querySnapshot.forEach((doc) => {
            partiesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setPartiesList(partiesListFetched)
         console.log('parties list fetched by country and level: ', partiesListFetched)
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to image upload
   const [uploadedImageFlag, setUploadedImageFlag] = useState(null)
   const [newPartyId, setNewPartyId] = useState(null)
   const [selectedImageFlag, setSelectedImageFlag] = useState(null)
   const [imageUrlFlag, setImageUrlFlag] = useState(null)
   const refImageInputFlag = useRef()

   function handleChangeImageInputFlag(e) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (e.target.files[0]) {
               setSelectedImageFlag(e.target.files[0])
               // setImageUrlFlag(URL.createObjectURL(e.target.files[0]))
            } else {
               setSelectedImageFlag(null)
               setImageUrlFlag(null)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedImageFlag) {
         setImageUrlFlag(URL.createObjectURL(selectedImageFlag))
      }
   }, [selectedImageFlag])

   useEffect(() => {
      if (uploadedImageFlag) {
         const newParty = partiesList.filter((item) => item.id !== newPartyId)
         newParty.Flag = uploadedImageFlag
      }
   }, [uploadedImageFlag])

   function RemoveImageFlag(e) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedImageFlag(null)
            setImageUrlFlag(null)
            refImageInputFlag.current.value = ''
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // relate to form filling and submission
   const [lastParty, setLastParty] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   function handlePartyDetails(event) {
      event.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setPartyDetails({ ...partyDetails, [event.target.name]: event.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleAddParty = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (partyDetails.Name.length >= 3 && partyDetails.Country_id !== '' && partyDetails.Level !== '') {
               partyDetails.CrBy = auth.currentUser.uid
               partyDetails.CrDt = new Date()
               partyDetails.CrIP = ip.data.ip

               partyDetails.NameOld = [
                  {
                     Name: partyDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (partyDetails.NameHindi !== '') {
                  partyDetails.NameHindiOld = [
                     { Name: partyDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (partyDetails.NameLocal !== '') {
                  partyDetails.NameLocalOld = [
                     {
                        Name: partyDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               if (partyDetails.Abbreviation !== '') {
                  partyDetails.AbbreviationOld = [
                     {
                        Abbreviation: partyDetails.Abbreviation,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               if (partyDetails.Symbol !== '') {
                  partyDetails.SymbolOld = [
                     {
                        Symbol: partyDetails.Symbol,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               if (partyDetails.Logo !== '') {
                  partyDetails.LogoOld = [
                     {
                        Logo: partyDetails.Logo,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }

               setLastParty(partyDetails.Name)
               setUploading(true)
               setPartyDetails({
                  Name: '',
                  NameOld: [],
                  NameAlt: [],
                  NameAltOld: [],
                  NameHindi: '',
                  NameHindiOld: [],
                  NameLocal: '',
                  NameLocalOld: [],
                  Abbreviation: '',
                  AbbreviationOld: [],
                  ECIStatus: '',
                  ECIStatusOld: [],
                  ECIStatusDate: '',
                  Country_id: selectedCountry,
                  Level: selectedLevel,
                  State_ids: [],
                  StateNames: [],
                  Flag: null,
                  FlagOld: [],
                  Symbol: null,
                  SymbolOld: [],
                  Logo: '',
                  LogoOld: [],
                  Headquarter: addressDetails,
                  HeadquarterOld: [],
                  Newspapers: [],
                  NewspapersOld: [],
                  Thinktanks: [],
                  ThinktanksOld: [],
                  Websites: [],
                  WebsitesOld: [],
                  Active: true,
                  ActiveOld: [],
                  CrBy: '',
                  CrDt: '',
                  CrIP: '',
                  CrDtSys: '',
               })
               console.log('partyDetails 1: ', partyDetails)
               try {
                  const docRef = await addDoc(collection(db, 'PoliticalParties'), partyDetails)
                  console.log('created the doc')

                  partyDetails.id = docRef.id
                  const thisDocRef = doc(db, 'PoliticalParties', docRef.id)
                  const docRefUpdate = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')

                  // image upload
                  if (selectedImageFlag !== null) {
                     console.log('entered the image part')
                     // const resizedImage = await resizeImage(selectedImageFlag)
                     const imageRef = ref(
                        dbStorage,
                        `PoliticalParties/${docRef.id}/Party/${selectedImageFlag.name + v4()}`,
                     )
                     uploadBytes(imageRef, selectedImageFlag).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                           partyDetails.Flag = url

                           setNewPartyId(docRef.id)
                           setUploadedImageFlag(url)

                           updateDoc(thisDocRef, {
                              Flag: url,
                              FlagOld: arrayUnion({
                                 Flag: url,
                                 CrBy: auth.currentUser.uid,
                                 CrDt: new Date(),
                                 CrIP: ip.data.ip,
                              }),
                           })

                           updateDoc(docRefUpdate, {
                              Parties: arrayUnion({
                                 Party_id: docRef.id,
                                 Name: partyDetails.Name,
                                 NameHindi: partyDetails.NameHindi,
                                 NameLocal: partyDetails.NameLocal,
                                 Abbreviation: partyDetails.Abbreviation,
                                 ECIStatus: partyDetails.ECIStatus,
                                 Active: true,
                                 Flag: url,
                                 Symbol: partyDetails.Symbol,
                              }),
                              CountActive: increment(1),
                              CountBlank: increment(1),
                           })
                        })
                     })
                  } else {
                     await updateDoc(docRefUpdate, {
                        Parties: arrayUnion({
                           Party_id: docRef.id,
                           Name: partyDetails.Name,
                           NameHindi: partyDetails.NameHindi,
                           NameLocal: partyDetails.NameLocal,
                           Abbreviation: partyDetails.Abbreviation,
                           ECIStatus: partyDetails.ECIStatus,
                           Active: true,
                           Flag: '',
                           Symbol: partyDetails.Symbol,
                        }),
                        CountActive: increment(1),
                        CountBlank: increment(1),
                     })
                  }
                  console.log('came out of image part')

                  // set data of local state
                  setPartiesList([partyDetails, ...partiesList])
                  console.log('partyDetails 3: ', partyDetails)
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
                  console.log('reached foot of code')
                  setSelectedImageFlag(null)
                  setImageUrlFlag(null)
                  refImageInputFlag.current.value = ''
               } catch (error) {
                  alert('Error adding party name')
                  console.log(error.message)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits

   const handleDelete = async (
      e,
      id,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisAbbreviation,
      thisECIStatus,
      thisActive,
      thisFlag,
      thisSymbol,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            setUploading(false)
            setUploaded(false)
            setUploadingFailed(false)
            if (confirm('Are you sure you want to erase this party?')) {
               let filePath = ''
               if (thisFlag) {
                  const decodedUrl = decodeURIComponent(thisFlag)
                  const startIndex = decodedUrl.indexOf('/o/') + 3
                  const endIndex = decodedUrl.indexOf('?')
                  filePath = decodedUrl.substring(startIndex, endIndex)

                  const desertRef = ref(dbStorage, filePath)
                  deleteObject(desertRef)
                     .then(() => {
                        // File deleted successfully
                     })
                     .catch((error) => {
                        console.log(error.message)
                     })
               }

               try {
                  await deleteDoc(doc(db, 'PoliticalParties', id))

                  const detailsForDeleteUpdate = {
                     Party_id: id,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     Abbreviation: thisAbbreviation,
                     ECIStatus: thisECIStatus,
                     Active: thisActive,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                  }
                  const docRefUpdate = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
                  await updateDoc(docRefUpdate, {
                     Parties: arrayRemove(detailsForDeleteUpdate),
                     CountActive: increment(-1),
                  })

                  setPartiesList(partiesList.filter((item) => item.id !== id))
               } catch (error) {
                  alert('Error deleting party')
                  console.log('error deleting: ', error.message)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <div className={styles.main_div}>
         <Box
            sx={{
               mt: 2,
               mb: 1,
               mx: 5,
               p: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch' },
               '& .MuiTextField-root': { width: '50ch', backgroundColor: '#ffffff' },
               '& .MuiFormControl-root': { width: '50ch' },
               justifyContent: 'center',
               alignItems: 'center',
               background: 'linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)',
            }}
         >
            <Typography
               variant="subtitle1"
               sx={{
                  textAlign: 'center',
                  fontSize: 14,
                  display: 'inline',
               }}
            >
               <strong>List a political party:</strong>
            </Typography>
            <FormControl sx={{ my: 1 }} size="small">
               <InputLabel id="demo-select-small-label">Country</InputLabel>
               <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedCountry}
                  label="Country"
                  onChange={(e) => {
                     handleCountryChange(e)
                  }}
                  sx={{ backgroundColor: '#FFFFFF' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {countries.map((item) => (
                     <MenuItem key={item.id} value={item.id}>
                        {item.Name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            {showLevel && (
               <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box>
                     <FormControl sx={{ my: 1 }} size="small">
                        <InputLabel id="demo-select-small-label">Level</InputLabel>
                        <Select
                           labelId="demo-select-small-label"
                           id="demo-select-small"
                           value={selectedLevel}
                           label="Level"
                           onChange={(e) => {
                              handleLevelChange(e)
                           }}
                           sx={{ backgroundColor: '#FFFFFF' }}
                        >
                           <MenuItem value="">
                              <em>None</em>
                           </MenuItem>
                           {levels &&
                              levels.map((item) => (
                                 <MenuItem key={item.id} value={item.id}>
                                    {item.Name}
                                 </MenuItem>
                              ))}
                        </Select>
                     </FormControl>
                  </Box>
                  {/* <Box>
                     <DatePicker
                        label="Recognition Date"
                        name="ECIStatusDate"
                        value={partyDetails.DateStart != null ? dayjs(elecDetails.DateStart) : null}
                        format="DD-MMM-YYYY"
                        onChange={(newValue) => setDateStart(newValue)}
                        views={['year', 'month', 'day']}
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     />
                  </Box> */}
               </Box>
            )}

            {showForm && (
               <div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={handlePartyDetails}
                        value={partyDetails.Name}
                        name="Name"
                        id="name"
                        label="Name"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={handlePartyDetails}
                        value={partyDetails.NameHindi}
                        name="NameHindi"
                        id="nameHindi"
                        label="Name in Hindi"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={handlePartyDetails}
                        value={partyDetails.NameLocal}
                        name="NameLocal"
                        id="nameLocal"
                        label="Name in Local Language"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={handlePartyDetails}
                        value={partyDetails.Abbreviation}
                        name="Abbreviation"
                        id="abbreviation"
                        label="Abbreviation"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                  </div>
                  <div>
                     <h6 className={styles.margin_0}>Add Image:</h6>
                     <div className={styles.flexRow}>
                        <input
                           type="file"
                           id="select-image-flag"
                           onChange={handleChangeImageInputFlag}
                           accept="image/*"
                           ref={refImageInputFlag}
                        />
                        {imageUrlFlag && selectedImageFlag && (
                           <Button
                              variant="outlined"
                              sx={{ p: 0, textTransform: 'none' }}
                              onClick={(e) => {
                                 RemoveImageFlag(e)
                              }}
                           >
                              {' '}
                              Remove Flag
                           </Button>
                        )}
                     </div>
                     {imageUrlFlag && selectedImageFlag && (
                        <img src={imageUrlFlag} alt={selectedImageFlag.name} className={styles.imgX} />
                     )}
                  </div>
                  <div className={styles.alertDiv}>
                     {uploading && (
                        <MyLoaderCircularGradient title={'Listing the constituency ...'}></MyLoaderCircularGradient>
                     )}
                     {uploaded && (
                        <Alert variant="outlined" severity="success">
                           <strong>{lastParty}</strong> listed successfully.
                        </Alert>
                     )}
                     {uploadingFailed && (
                        <Alert variant="outlined" severity="error">
                           Error occured! <strong>{lastParty}</strong> could not be listed.
                        </Alert>
                     )}
                  </div>
                  <div className={styles.text_align_center}>
                     <Button
                        type="submit"
                        align="center"
                        variant="contained"
                        color="primary"
                        size="small"
                        margin="dense"
                        onClick={(e) => {
                           handleAddParty(e)
                        }}
                        disabled={
                           partyDetails.Name.length < 3 || partyDetails.Level === '' || partyDetails.Country_id === ''
                        }
                     >
                        Submit
                     </Button>
                  </div>
               </div>
            )}
         </Box>
         <Box
            sx={{
               mt: 2,
               mb: 1,
               mx: 5,
               px: 1,
               display: 'flex',
               flexDirection: 'column',
               background: 'linear-gradient(to right, #d5ddbb, #f2f2f2, #dbdbdb, #d5ddbb)',
            }}
         >
            {selectedCountry && selectedLevel ? (
               <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                  Political parties of <strong>{selectedCountryText}</strong> at level{' '}
                  <strong>{selectedLevelText}</strong>:
               </Typography>
            ) : (
               <div>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                     Political parties:
                  </Typography>
               </div>
            )}
         </Box>
         <Box
            sx={{
               mt: 1,
               mb: 1,
               mx: 5,
               display: 'flex',
               flexDirection: 'column',
            }}
         >
            {showPartiesList && partiesList.length > 0 ? (
               partiesList.map((item, index) => (
                  <Box
                     key={item.id}
                     sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        px: 2,
                        py: 1,
                        minWidth: 300,
                        my: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundImage: `linear-gradient(
                           to bottom,
                           rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%
                           )`,
                     }}
                  >
                     <Box sx={{ color: '#FFFFFF', fontSize: 14, width: 25 }}>{index + 1}.</Box>
                     <Box sx={{ p: 0 }}>
                        <img src={item.Flag} className={styles.imgX2} />
                     </Box>
                     <Box sx={{ marginRight: 'auto', pl: 1 }}>
                        <Box sx={{ color: '#FF5F1F', fontSize: 14, display: 'flex', flexDirection: 'row' }}>
                           <CopyToClipboard text={item.id} onCopy={() => onCopyText(index)}>
                              <Button variant="contained" color="success" size="small">
                                 id: {item.id}
                              </Button>
                           </CopyToClipboard>
                           {copyStatus && indexOfCopy === index && <p> ... id copied!</p>}
                        </Box>
                        <Box sx={{ color: '#ffffff', fontSize: 18, fontWeight: 'medium' }}>{item.Name}</Box>
                        <Box
                           sx={{
                              mx: 0.5,
                              fontSize: 14,
                           }}
                        >
                           <Link
                              href={`/political-party/${item.Name}/${item.id}`}
                              color="#00FFFF"
                              rel="noreferrer"
                              target="_blank"
                           >
                              <InfoSharpIcon />
                           </Link>
                        </Box>
                     </Box>
                     <Box sx={{ fontSize: 12, display: 'flex', flexDirection: 'column' }}>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color={'error'}
                           size="small"
                           sx={{ textTransform: 'none', fontWeight: '400', px: 1, py: 0, minWidth: 0 }}
                           onClick={(e) => {
                              handleDelete(
                                 e,
                                 item.Party_id,
                                 item.Name,
                                 item.NameHindi,
                                 item.NameLocal,
                                 item.Abbreviation,
                                 item.ECIStatus,
                                 item.Active,
                                 item.Flag,
                                 item.Symbol,
                              )
                           }}
                        >
                           Delete
                        </Button>
                     </Box>
                  </Box>
               ))
            ) : (
               <div>
                  <Alert variant="outlined" severity="warning">
                     No party to show!
                  </Alert>
               </div>
            )}
         </Box>
      </div>
   )
}

export default PPartiesAdmin
