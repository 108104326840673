import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function HomeCentralExecutive({ props }) {
   const individualCountry = props

   const [initialState, setInitialState] = useState('idle')
   const [legisListExecutive, setLegisListExecutive] = useState('')
   const [legisListMinistry, setLegisListMinistry] = useState('')
   const [legisListIndependentDepartment, setLegisListIndependentDepartment] = useState('')
   const [legisListIndependentBody, setLegisListIndependentBody] = useState('')

   useEffect(() => {
      if (initialState === 'idle') {
         const list = individualCountry.Legislatures
         const listExecutive = list.filter((item) => item.Type === 'Executive')
         const listMinistry = list.filter((item) => item.Type === 'Ministry')
         const listIndependentDepartment = list.filter((item) => item.Type === 'Independent Department')
         const listIndependentBody = list.filter((item) => item.Type === 'Apex / Independent Body')

         const sortedListExecutive = [...listExecutive].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListMinistry = [...listMinistry].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListIndependentDepartment = [...listIndependentDepartment].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListIndependentBody = [...listIndependentBody].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setLegisListExecutive(sortedListExecutive)
         setLegisListMinistry(sortedListMinistry)
         setLegisListIndependentDepartment(sortedListIndependentDepartment)
         setLegisListIndependentBody(sortedListIndependentBody)

         setInitialState('success')
      }
   }, [])

   return (
      <Box sx={{ p: 0.5 }}>
         <Box>
            <Typography variant="button" display="block" gutterBottom>
               Head Executive:
            </Typography>
            <List dense>
               {legisListExecutive && legisListExecutive.length > 0 ? (
                  legisListExecutive.map((item, index) => (
                     <Link
                        key={item.Legislature_id}
                        href={`/executive-central/${item.Name}/${item.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <ListItem sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Avatar alt="Travis Howard" src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', lineHeight: 1.1 }}
                              primaryTypographyProps={{ lineHeight: 1.1 }}
                              primary={item.Name}
                              secondary={item.NameHindi}
                           />
                        </ListItem>
                        <Divider />
                     </Link>
                  ))
               ) : (
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               )}
            </List>
         </Box>
         <Box>
            <Typography variant="button" display="block" gutterBottom>
               Ministries:
            </Typography>
            <List dense>
               {legisListMinistry && legisListMinistry.length > 0 ? (
                  legisListMinistry.map((item, index) => (
                     <Link
                        key={item.Legislature_id}
                        href={`/ministry/${item.Name}/${item.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <ListItem sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Avatar alt="Travis Howard" src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', lineHeight: 1.1 }}
                              primaryTypographyProps={{ lineHeight: 1.1 }}
                              primary={item.Name}
                              secondary={item.NameHindi}
                           />
                        </ListItem>
                        <Divider />
                     </Link>
                  ))
               ) : (
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               )}
            </List>
         </Box>
         <Box>
            <Typography variant="button" display="block" gutterBottom>
               Independent Departments:
            </Typography>
            <List dense>
               {legisListIndependentDepartment && legisListIndependentDepartment.length > 0 ? (
                  legisListIndependentDepartment.map((item, index) => (
                     <Link
                        key={item.Legislature_id}
                        href={`/ministry/${item.Name}/${item.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <ListItem sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Avatar alt="Travis Howard" src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', lineHeight: 1.1 }}
                              primaryTypographyProps={{ lineHeight: 1.1 }}
                              primary={item.Name}
                              secondary={item.NameHindi}
                           />
                        </ListItem>
                        <Divider />
                     </Link>
                  ))
               ) : (
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               )}
            </List>
         </Box>
         <Box>
            <Typography variant="button" display="block" gutterBottom>
               Apex / Independent Bodies:
            </Typography>
            <List dense>
               {legisListIndependentBody && legisListIndependentBody.length > 0 ? (
                  legisListIndependentBody.map((item, index) => (
                     <Link
                        key={item.Legislature_id}
                        href={`/legislature/${item.Name}/${item.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <ListItem sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Avatar alt="Travis Howard" src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', lineHeight: 1.1 }}
                              primaryTypographyProps={{ lineHeight: 1.1 }}
                              primary={item.Name}
                              secondary={item.NameHindi}
                           />
                        </ListItem>
                        <Divider />
                     </Link>
                  ))
               ) : (
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               )}
            </List>
         </Box>
      </Box>
   )
}

HomeCentralExecutive.propTypes = {
   props: PropTypes.object.isRequired,
}

export default HomeCentralExecutive
