import { Alert, Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function ElecFilters({ legis, elec }) {
   return (
      <Box>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && <Box>Filters ...</Box>}
      </Box>
   )
}

ElecFilters.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
}

export default ElecFilters
