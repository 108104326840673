import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'

function MinistryOB() {
   return (
      <Box>
         <Alert
            variant="outlined"
            severity="warning"
            sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
         >
            <AlertTitle sx={{ fontSize: 12 }}>
               This service will be available soon. <br /> यह सेवा शीघ्र ही उपलब्ध होगी|
            </AlertTitle>
         </Alert>
      </Box>
   )
}

export default MinistryOB
