import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box } from '@mui/material'

function ElecOrders({ props }) {
   const elec = props
   console.log('value from props from inside elec orders page: ', elec)
   return (
      <Box>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && <Box>Election orders ...</Box>}
      </Box>
   )
}

ElecOrders.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecOrders
