import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'

import myStyles from './ElecCandiBasicInfo.module.css'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { v4 } from 'uuid'
import moment from 'moment'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddImage from '../../../Components/Edits/AddImage'
import AddAddress from '../../../Components/Edits/AddAddress'
import AddText from '../../../Components/Edits/AddText'
import AddEmail from '../../../Components/Edits/AddEmail'

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import AddPhone from '../../../Components/Edits/AddPhone'
import AddDate from '../../../Components/Edits/AddDate'
import AddNumber from '../../../Components/Edits/AddNumber'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'

function ElecCandiBasicInfo({ candi, elec }) {
   const candidate = candi
   const election = elec

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [record, setRecord] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setRecord({
            Candidate_id: candidate.id,
            Name: candidate.Name,
            NameHindi: candidate.NameHindi,
            NameLocal: candidate.NameLocal,
            PhaseNum: candidate.PhaseNum,
            Constituency_id: candidate.Constituency_id,
            ConstituencyName: candidate.ConstituencyName,
            ConstituencyNameHindi: candidate.ConstituencyNameHindi,
            ConstituencyNameLocal: candidate.ConstituencyNameLocal,
            Age: candidate.Age,
            DoB: candidate.DoB,
            Gender: candidate.Gender,
            Party_id: candidate.Party_id,
            PartyName: candidate.PartyName,
            PartyNameHindi: candidate.PartyNameHindi,
            PartyNameLocal: candidate.PartyNameLocal,
            PartyAbbreviation: candidate.PartyAbbreviation,
            PartyFlag: candidate.PartyFlag,
            PartySymbol: candidate.PartySymbol,
            AllottedSymbol: candidate.AllottedSymbol,
            Alliances: candidate.Alliances,
            Image: candidate.Image,
            CoreListId: candidate.CoreListId,
            Announced: candidate.Announced,
            AnnouncedDate: candidate.AnnouncedDate,
            AnnounceCancelled: candidate.AnnounceCancelled,
            AnnounceCancelledDate: candidate.AnnounceCancelledDate,
            Filed: candidate.Filed,
            FiledDate: candidate.FiledDate,
            Accepted: candidate.Accepted,
            AcceptedDate: candidate.AcceptedDate,
            Rejected: candidate.Rejected,
            RejectedDate: candidate.RejectedDate,
            Withdrawn: candidate.Withdrawn,
            WithdrawnDate: candidate.WithdrawnDate,
         })

         setFirstLoadStatus('success')

         console.log('ElecCandis - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (record !== '') {
         console.log('record', record)
      }
   }, [record])

   const [sourceAllottedSymbol, setSourceAllottedSymbol] = useState(candidate.AllottedSymbol)
   const [sourceImage, setSourceImage] = useState(candidate.Image)

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const candiRef = doc(db, 'ElectionCandidates', candidate.id)
            const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

            await updateDoc(candiRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            await updateDoc(candiCoreRef, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(candiCoreRef, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.Name = textDetails.Text
            setRecord({ ...record, Name: textDetails.Text })
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const candiRef = doc(db, 'ElectionCandidates', candidate.id)
            const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

            await updateDoc(candiRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameHindi: textDetails.Text }

            await updateDoc(candiCoreRef, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(candiCoreRef, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.NameHindi = textDetails.Text
            setRecord({ ...record, NameHindi: textDetails.Text })
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const candiRef = doc(db, 'ElectionCandidates', candidate.id)
            const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

            await updateDoc(candiRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameLocal: textDetails.Text }

            await updateDoc(candiCoreRef, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(candiCoreRef, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.NameLocal = textDetails.Text
            setRecord({ ...record, NameLocal: textDetails.Text })
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DoB modal

   const [openModalDoB, setOpenModalDoB] = useState(false)

   const handleOpenModalDoB = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalDoB(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalDoB = () => setOpenModalDoB(false)

   const handleAddDoB = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.Date !== '') {
               const dobForOld = {
                  DoB: dateDetails.Date,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               const candiRef = doc(db, 'ElectionCandidates', candidate.id)
               const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

               await updateDoc(candiRef, {
                  Dob: dateDetails.Date,
                  DobOld: arrayUnion(dobForOld),
               })

               const recordNew = { ...record, DoB: dateDetails.Date }

               await updateDoc(candiCoreRef, {
                  Candidates: arrayRemove(record),
               })

               await updateDoc(candiCoreRef, {
                  Candidates: arrayUnion(recordNew),
               })

               // update the name display
               candidate.DoB = dateDetails.Date
               setRecord({ ...record, Dob: dateDetails.Date })

               candidate.DoBOld.push(dobForOld)
               setOpenModalDoB(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Age modal

   const [openModalAge, setOpenModalAge] = useState(false)

   const handleOpenModalAge = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAge(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAge = () => setOpenModalAge(false)

   const handleAddAge = async (numberDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (numberDetails.Number !== '') {
               const ageForOld = {
                  Age: numberDetails.Number,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               const candiRef = doc(db, 'ElectionCandidates', candidate.id)
               const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

               await updateDoc(candiRef, {
                  Age: numberDetails.Number,
                  AgeOld: arrayUnion(ageForOld),
               })

               const recordNew = { ...record, Age: numberDetails.Number }

               await updateDoc(candiCoreRef, {
                  Candidates: arrayRemove(record),
               })

               await updateDoc(candiCoreRef, {
                  Candidates: arrayUnion(recordNew),
               })

               // update the age display
               candidate.Age = numberDetails.Number
               setRecord({ ...record, Age: numberDetails.Number })

               candidate.AgeOld.push(ageForOld)
               setOpenModalAge(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               addressDetails.State_id.length >= 3 &&
               addressDetails.Address.length >= 10 &&
               addressDetails.ValueRadio !== ''
            ) {
               ///
               addressDetails.CrBy = auth.currentUser.uid
               addressDetails.CrDt = new Date()
               addressDetails.CrIP = ip.data.ip
               console.log('stage 4 submit')

               addressDetails.Address = sentenceCase(addressDetails.Address)
               console.log('stage 5 submit')

               const candiRef = doc(db, 'ElectionCandidates', candidate.id)

               if (addressDetails.ValueRadio === 'Current address') {
                  ///
                  await updateDoc(candiRef, {
                     Address: addressDetails,
                     AddressOld: arrayUnion(addressDetails),
                  })
                  console.log('stage 6 submit')
                  // update the address display
                  candidate.Address.Address = addressDetails.Address
                  candidate.Address.DistrictName = addressDetails.DistrictName
                  candidate.Address.StateName = addressDetails.StateName
                  candidate.Address.PinCode = addressDetails.PinCode
                  console.log('stage 7 submit')
               } else {
                  await updateDoc(candiRef, {
                     AddressOld: arrayUnion(addressDetails),
                  })
                  console.log('stage 8 submit')
               }

               // close modal
               setOpenModalAddress(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //

            const imageRef = ref(dbStorage, `ElectionCandidates/${elec.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, Image: url }

                  const candidateRef = doc(db, 'ElectionCandidates', candidate.id)
                  const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)
                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     await updateDoc(candidateRef, {
                        Image: url,
                        ImageOld: arrayUnion(forOld),
                     })

                     await updateDoc(candiCoreRef, {
                        Candidates: arrayRemove(record),
                     })

                     await updateDoc(candiCoreRef, {
                        Candidates: arrayUnion(recordNew),
                     })

                     setRecord({ ...record, Image: url })
                     setSourceImage(url)
                  } else {
                     await updateDoc(candidateRef, {
                        ImageOld: arrayUnion(forOld),
                     })
                  }
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Allotted Symbol modal
   const [openModalAllottedSymbol, setOpenModalAllottedSymbol] = useState(false)

   const handleOpenModalAllottedSymbol = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAllottedSymbol(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAllottedSymbol = () => setOpenModalAllottedSymbol(false)

   const handleAddImageAllottedSymbol = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `ElectionCandidates/${elec.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     AllottedSymbol: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, AllottedSymbol: url }

                  const candiRef = doc(db, 'ElectionCandidates', candidate.id)
                  const candiCoreRef = doc(db, 'ElectionCandidates', candidate.CoreListId)

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     updateDoc(candiRef, {
                        AllottedSymbol: url,
                        AllottedSymbolOld: arrayUnion(forOld),
                     })

                     await updateDoc(candiCoreRef, {
                        Candidates: arrayRemove(record),
                     })

                     await updateDoc(candiCoreRef, {
                        Candidates: arrayUnion(recordNew),
                     })

                     setRecord({ ...record, AllottedSymbol: url })

                     setSourceAllottedSymbol(url)
                  } else {
                     updateDoc(candiRef, {
                        AllottedSymbolOld: arrayUnion(forOld),
                     })
                  }
               })
            })

            // close modal
            setOpenModalAllottedSymbol(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   const [lastPhone, setLastPhone] = useState('')
   const [uploadingPhone, setUploadingPhone] = useState(false)
   const [uploadedPhone, setUploadedPhone] = useState(false)
   const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            setLastPhone(phoneDetails.Phone.toString())
            setUploadingPhone(true)
            try {
               const partyRef = doc(db, 'ElectionCandidates', candidate.id)
               await updateDoc(partyRef, {
                  Phones: arrayUnion(phoneDetails.Phone),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the website display
               candidate.Phones.push(phoneDetails.Phone)

               // close modal
               // setOpenModalPhone(false)

               setUploadingPhone(false)
               setUploadedPhone(true)
               setUploadingFailedPhone(false)
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
               setUploadingPhone(false)
               setUploadedPhone(false)
               setUploadingFailedPhone(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const partyRef = doc(db, 'ElectionCandidates', candidate.id)
            await updateDoc(partyRef, {
               WebLinks: arrayUnion(weblinkDetails.Weblink),
               WebLinksOld: arrayUnion(weblinkDetails),
            })

            // update the website display
            candidate.WebLinks.push(weblinkDetails.Weblink)

            // close modal
            // setOpenModalWebsite(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip

            const partyRef = doc(db, 'ElectionCandidates', candidate.id)
            await updateDoc(partyRef, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the website display
            candidate.Emails.push(emailDetails.Email)

            // close modal
            setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // const [foundIcon, setFoundIcon] = useState('')

   // function smSubstring(name) {
   //    if (name.substring(0, 25) === 'https://www.facebook.com/') {
   //       setFoundIcon('FacebookIcon')
   //    } else if (name.substring(0, 26) === 'https://www.instagram.com/') {
   //       setFoundIcon('InstagramIcon')
   //    } else if (name.substring(0, 25) === 'https://www.linkedin.com/') {
   //       setFoundIcon('LinkedInIcon')
   //    } else if (name.substring(0, 14) === 'https://x.com/') {
   //       setFoundIcon('XIcon')
   //    } else {
   //       setFoundIcon('')
   //    }
   // }

   console.log('value from props from inside Elec candi Basic Info : ', election)
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Table>
                  <tbody>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Constituency:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>
                                 {candidate.ConstituencyNameLocal
                                    ? `${candidate.ConstituencyLocal} (${candidate.ConstituencyNumber}) - ${candidate.ConstituencyName} - ${candidate.ConstituencyNameHindi}`
                                    : `${candidate.ConstituencyName} (${candidate.ConstituencyNumber}) - ${candidate.ConstituencyNameHindi}`}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.Name}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalName}
                                    onClose={handleCloseModalName}
                                    aria-labelledby="modal-modal-title-name"
                                    aria-describedby="modal-modal-description-name"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-name"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name
                                       </Typography>
                                       <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.NameHindi}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameHindi}
                                    onClose={handleCloseModalNameHindi}
                                    aria-labelledby="modal-modal-title-Hindiname"
                                    aria-describedby="modal-modal-description-Hindiname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-hindiname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in hindi
                                       </Typography>
                                       <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name local:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.NameLocal}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameLocal}
                                    onClose={handleCloseModalNameLocal}
                                    aria-labelledby="modal-modal-title-localname"
                                    aria-describedby="modal-modal-description-localname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-localname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in local language
                                       </Typography>
                                       <AddText
                                          handleAddText={handleAddNameLocal}
                                          labelName="Name (in local language)"
                                       ></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Birth date {'\n'} जन्म तिथि:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.DoB !== '' && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                       <Box
                                          sx={{
                                             mr: 1,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          Notified:
                                       </Box>
                                       <Box>{moment(candidate.DoB.toDate()).format('Do MMMM YYYY')}</Box>
                                    </Box>
                                 )}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDoB"
                                    onClick={(e) => {
                                       handleOpenModalDoB(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalDoB}
                                 onClose={handleCloseModalDoB}
                                 aria-labelledby="modal-modal-title-dob"
                                 aria-describedby="modal-modal-description-dob"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-dob"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Date Of Birth
                                    </Typography>
                                    <AddDate
                                       labelName="Date of Birth / जन्म तिथि"
                                       handleAddDate={handleAddDoB}
                                    ></AddDate>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Age {'\n'} आयु:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>{candidate.Age}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAge"
                                    onClick={(e) => {
                                       handleOpenModalAge(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalAge}
                                 onClose={handleCloseModalAge}
                                 aria-labelledby="modal-modal-title-age"
                                 aria-describedby="modal-modal-description-age"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-age"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Age
                                    </Typography>
                                    <AddNumber label="Age / आयु" handleAddNumber={handleAddAge}></AddNumber>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Image:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editImage"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalImage}
                                    onClose={handleCloseModalImage}
                                    aria-labelledby="modal-modal-title-logo"
                                    aria-describedby="modal-modal-description-logo"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-logo"
                                          variant="h6"
                                          component="h2"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add image of Candidate
                                       </Typography>
                                       <AddImage handleAddImage={handleAddImage}></AddImage>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Party:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {candidate.PartyFlag && (
                                 <Box sx={{ mr: 1 }}>
                                    <img src={candidate.PartyFlag} className={myStyles.imgX} />
                                 </Box>
                              )}
                              <Box
                                 sx={{
                                    whiteSpace: 'pre-line',
                                 }}
                              >
                                 {candidate.PartyName} {'\n'} {candidate.PartyNameHindi} {'\n'}{' '}
                                 {candidate.PartyNameLocal}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Symbol {'\n'} चुनाव चिन्ह:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {candidate.PartySymbol && <img src={candidate.PartySymbol} className={myStyles.imgX} />}
                              {sourceAllottedSymbol && <img src={sourceAllottedSymbol} className={myStyles.imgX} />}
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAllottedSymbol"
                                    onClick={(e) => {
                                       handleOpenModalAllottedSymbol(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalAllottedSymbol}
                                 onClose={handleCloseModalAllottedSymbol}
                                 aria-labelledby="modal-modal-title-flag"
                                 aria-describedby="modal-modal-description-flag"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-flag"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add image of symbol
                                    </Typography>
                                    <AddImage handleAddImage={handleAddImageAllottedSymbol}></AddImage>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Alliances {'\n'} गठबंधन:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Alliances.map((itemAl, index) => (
                                    <>
                                       {index + 1}.
                                       <Link
                                          key={itemAl.Alliance_id}
                                          href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                          sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                          rel="noreferrer"
                                          target="_blank"
                                       >
                                          {itemAl.Abbreviation}
                                       </Link>
                                    </>
                                 ))}
                              </Box>

                              {/* {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )} */}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Address {'\n'} पता:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {candidate.Address && (
                                 <Box>
                                    <div>{candidate.Address.Address}</div>
                                    <span>
                                       {candidate.Address.DistrictName} - {candidate.Address.StateName} -{' '}
                                       {candidate.Address.PinCode}
                                    </span>
                                 </Box>
                              )}

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalAddress}
                                 onClose={handleCloseModalAddress}
                                 aria-labelledby="modal-modal-title-headquarter"
                                 aria-describedby="modal-modal-description-headquarter"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-headquarter"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Address
                                    </Typography>
                                    <AddAddress
                                       countryId={candidate.Country_id}
                                       handleAddAddress={handleAddAddress}
                                    ></AddAddress>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Phones {'\n'} दूरभाष:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Phones &&
                                    candidate.Phones.length > 0 &&
                                    candidate.Phones.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editPhone"
                                    onClick={(e) => {
                                       handleOpenModalPhone(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalPhone}
                                 onClose={handleCloseModalPhone}
                                 aria-labelledby="modal-modal-title-phones"
                                 aria-describedby="modal-modal-description-phones"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-phones"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Phones:
                                    </Typography>
                                    <AddPhone
                                       lastPhone={lastPhone}
                                       handleAddPhone={handleAddPhone}
                                       uploadingPhone={uploadingPhone}
                                       uploadedPhone={uploadedPhone}
                                       uploadingFailedPhone={uploadingFailedPhone}
                                    ></AddPhone>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Web Links {'\n'} वेब लिंक:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>
                                 {candidate.WebLinks &&
                                    candidate.WebLinks.length > 0 &&
                                    candidate.WebLinks.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <Link
                                                   underline="hover"
                                                   href={item}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                      <FacebookIcon />
                                                   )}
                                                   {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                      <InstagramIcon />
                                                   )}
                                                   {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                      <LinkedInIcon />
                                                   )}
                                                   {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                </Link>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <Link
                                                underline="hover"
                                                href={`//${item}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                   <FacebookIcon />
                                                ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                   <InstagramIcon />
                                                ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                   <LinkedInIcon />
                                                ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                   <XIcon sx={{ color: '#000000' }} />
                                                ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                   <YouTubeIcon />
                                                ) : (
                                                   item
                                                )}
                                             </Link>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalWebsite}
                                 onClose={handleCloseModalWebsite}
                                 aria-labelledby="modal-modal-title-website"
                                 aria-describedby="modal-modal-description-website"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-website"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a web link
                                    </Typography>
                                    <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Emails {'\n'} ईमेल:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Emails &&
                                    candidate.Emails.length > 0 &&
                                    candidate.Emails.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editEmail"
                                    onClick={(e) => {
                                       handleOpenModalEmail(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalEmail}
                                 onClose={handleCloseModalEmail}
                                 aria-labelledby="modal-modal-title-email"
                                 aria-describedby="modal-modal-description-email"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-email"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add an email
                                    </Typography>
                                    <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

ElecCandiBasicInfo.propTypes = {
   candi: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
}

export default ElecCandiBasicInfo
