import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import {
   Button,
   Box,
   Link,
   Modal,
   Typography,
   ListItemText,
   // Avatar,
   // ListItemAvatar,
   ListItem,
   Divider,
   List,
   IconButton,
   Alert,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import styles from './LegisElecs.module.css'
import { auth, db } from '../../../FirebaseConfig'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDoc,
   increment,
   updateDoc,
} from 'firebase/firestore'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import AddLegisElec from './AddLegisElec'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'

function LegisElecs({ props }) {
   const legis = props

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [elecList, setElecList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         console.log('gggg')
         setElecList(legis.Elections)
         console.log('ffff')
         setFirstLoadStatus('success')
      }
   }, [])

   // related to form filling and submission

   const [openModalAddElec, setOpenModalAddElec] = useState(false)
   const handleOpenModalAddElec = (e) => {
      e.preventDefault()
      setOpenModalAddElec(true)
   }
   const handleCloseModalAddElec = () => setOpenModalAddElec(false)

   const [lastElec, setLastElec] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddElec = async (elecDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               elecDetails.Name.length >= 3 &&
               elecDetails.DateStart !== '' &&
               elecDetails.DateFinish !== '' &&
               elecDetails.HouseNumber !== ''
            ) {
               elecDetails.CrBy = auth.currentUser.uid
               elecDetails.CrDt = new Date()
               elecDetails.CrIP = ip.data.ip

               elecDetails.LegislatureName = legis.Name
               elecDetails.LegislatureNameHindi = legis.NameHindi
               elecDetails.LegislatureNameLocal = legis.NameLocal
               elecDetails.Country_id = legis.Country_id
               elecDetails.CountryName = legis.CountryName
               elecDetails.State_id = legis.State_id
               elecDetails.StateName = legis.StateName
               elecDetails.Division_id = legis.Division_id
               elecDetails.DivisionName = legis.DivisionName
               elecDetails.District_id = legis.District_id
               elecDetails.DistrictName = legis.DistrictName
               elecDetails.SubDistrict_id = legis.SubDistrict_id
               elecDetails.SubDistrictName = legis.SubDistrictName
               elecDetails.Block_id = legis.Block_id
               elecDetails.BlockName = legis.BlockName
               elecDetails.Village_id = legis.Village_id
               elecDetails.VillageName = legis.VillageName

               elecDetails.NameOld = [
                  {
                     Name: elecDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (elecDetails.NameHindi !== '') {
                  elecDetails.NameHindiOld = [
                     { Name: elecDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (elecDetails.NameLocal !== '') {
                  elecDetails.NameLocalOld = [
                     {
                        Name: elecDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               elecDetails.DateStartOld = [
                  {
                     Name: elecDetails.DateStart,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (elecDetails.DateFinish !== '') {
                  elecDetails.DateFinishOld = [
                     {
                        Name: elecDetails.DateFinish,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }

               console.log('elecDetails 1: ', elecDetails)

               setLastElec(elecDetails.Name)
               setUploading(true)
               console.log('elecDetails 2: ', elecDetails, ' uploading: ', uploading)
               try {
                  const docRef = await addDoc(collection(db, 'Elections'), elecDetails)
                  elecDetails.id = docRef.id

                  const record = {
                     Election_id: docRef.id,
                     Legislature_id: legis.id,
                     LegislatureName: legis.Name,
                     LegislatureNameHindi: legis.NameHindi,
                     LegislatureNameLocal: legis.NameLocal,
                     Name: elecDetails.Name,
                     NameHindi: elecDetails.NameHindi,
                     NameLocal: elecDetails.NameLocal,
                     NameAlt: elecDetails.NameAlt,
                     DateStart: new Date(elecDetails.DateStart),
                     DateFinish: new Date(elecDetails.DateFinish),
                     HouseNumber: elecDetails.HouseNumber,
                     IsSpeculated: elecDetails.IsSpeculated,
                  }

                  // console.log('record 1: ', record)
                  console.log('Date: ', new Date(elecDetails.DateStart).toISOString())

                  const docRefUpdate = doc(db, 'Legislatures', legis.id)

                  await updateDoc(docRefUpdate, {
                     Elections: arrayUnion(record),
                  })

                  const docRefBaseList = doc(db, 'BaseLists', 'Elections-India-000001')
                  const docRefBaseListCore = doc(db, 'BaseLists', 'Elections-India')

                  await updateDoc(docRefBaseList, {
                     Elections: arrayUnion(record),
                     Count: increment(1),
                  })
                  await updateDoc(docRefBaseListCore, {
                     Count: increment(1),
                  })

                  // Note:
                  // setElecList([...elecList, record]) was causing problem / error for date adjustment in the list
                  // hence, on insert, we fetch the election data afresh, as follows

                  const docRefForList = doc(db, 'Legislatures', legis.id)
                  const docSnap = await getDoc(docRefForList)
                  setElecList(docSnap.data().Elections)

                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding election: ', error)
                  console.log('error adding election: ', error.message)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits

   const handleDelete = async (
      e,
      id,
      thisLegislatureId,
      thisLegislatureName,
      thisLegislatureNameHindi,
      thisLegislatureNameLocal,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisNameAlt,
      thisDateStart,
      thisDateFinish,
      thisHouseNumber,
      thisIsSpeculated,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            setUploading(false)
            setUploaded(false)
            setUploadingFailed(false)
            if (confirm('Are you sure you want to erase this election?')) {
               try {
                  await deleteDoc(doc(db, 'Elections', id))

                  const record = {
                     Election_id: id,
                     Legislature_id: thisLegislatureId,
                     LegislatureName: thisLegislatureName,
                     LegislatureNameHindi: thisLegislatureNameHindi,
                     LegislatureNameLocal: thisLegislatureNameLocal,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     NameAlt: thisNameAlt,
                     DateStart: thisDateStart,
                     DateFinish: thisDateFinish,
                     HouseNumber: thisHouseNumber,
                     IsSpeculated: thisIsSpeculated,
                  }

                  const docRefUpdate = doc(db, 'Legislatures', legis.id)

                  await updateDoc(docRefUpdate, {
                     Elections: arrayRemove(record),
                  })

                  const docRefBaseList = doc(db, 'BaseLists', 'Elections-India-000001')
                  const docRefBaseListCore = doc(db, 'BaseLists', 'Elections-India')

                  await updateDoc(docRefBaseList, {
                     Elections: arrayRemove(record),
                     Count: increment(-1),
                  })
                  await updateDoc(docRefBaseListCore, {
                     Count: increment(-1),
                  })

                  setElecList(elecList.filter((item) => item.Election_id !== id))
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <div className={styles.main_div}>
         <div>
            <Modal
               open={openModalAddElec}
               onClose={handleCloseModalAddElec}
               aria-labelledby="modal-modal-title-addElec"
               aria-describedby="modal-modal-description-addElec"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addElec"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add an election
                  </Typography>
                  <AddLegisElec
                     legisId={legis.id}
                     lastElec={lastElec}
                     uploading={uploading}
                     uploaded={uploaded}
                     uploadingFailed={uploadingFailed}
                     handleAddElec={handleAddElec}
                  ></AddLegisElec>
               </Box>
            </Modal>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363, #FFFFFF)',
               pl: 1,
            }}
         >
            Elections / <strong>चुनाव</strong>:
         </Typography>
         <Box sx={{ display: 'flex', px: 1, mt: 1 }}>
            <Button
               onClick={(e) => {
                  handleOpenModalAddElec(e)
               }}
               size="small"
               variant="outlined"
               sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
            >
               Add an election
            </Button>
         </Box>
         <Box sx={{ mx: 1, mt: 1 }}>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper', my: 1, p: 0 }}>
               {firstLoadStatus !== 'idle' &&
                  elecList.length > 0 &&
                  elecList.map((item) => (
                     <div key={item.Election_id}>
                        <ListItem
                           alignItems="flex-start"
                           key={item.Election_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDelete(
                                             e,
                                             item.Election_id,
                                             item.Legislature_id,
                                             item.LegislatureName,
                                             item.LegislatureNameHindi,
                                             item.LegislatureNameLocal,
                                             item.Name,
                                             item.NameHindi,
                                             item.NameLocal,
                                             item.NameAlt,
                                             item.DateStart,
                                             item.DateFinish,
                                             item.HouseNumber,
                                             item.IsSpeculated,
                                          )
                                       }}
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left' }}
                              primary=<Link
                                 href={`/election/${item.Name}/${item.Election_id}`}
                                 rel="noreferrer"
                                 target="_blank"
                              >
                                 {item.Name}
                              </Link>
                              secondary={
                                 <React.Fragment>
                                    {item.NameHindi !== '' && (
                                       <>
                                          <Link
                                             href={`/election/${item.Name}/${item.Election_id}`}
                                             rel="noreferrer"
                                             target="_blank"
                                          >
                                             {item.NameHindi}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    {item.NameLocal !== '' && (
                                       <>
                                          <Link
                                             href={`/election/${item.Name}/${item.Election_id}`}
                                             rel="noreferrer"
                                             target="_blank"
                                          >
                                             {item.NameLocal}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    {' Poll start: '}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {moment(item.DateStart.toDate()).format('Do MMMM YYYY')}
                                    </Typography>

                                    {item.DateFinish !== '' && (
                                       <>
                                          <br />
                                          {' Poll end: '}
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             {moment(item.DateFinish.toDate()).format('Do MMMM YYYY')}
                                          </Typography>
                                          {item.IsSpeculated && (
                                             <>
                                                <br />
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="Crimson"
                                                >
                                                   <strong>
                                                      <u>Disclaimer:</u>
                                                   </strong>{' '}
                                                   The <strong>dates</strong> of this election{' '}
                                                   <strong>are speculated</strong> for the purpose of display. Please
                                                   wait for the actual announcement by the Election Commission of India.
                                                </Typography>
                                                <br />
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="Crimson"
                                                >
                                                   <strong>
                                                      <u>अस्वीकरण:</u>
                                                   </strong>{' '}
                                                   इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से{' '}
                                                   <strong>अनुमान</strong> हैं। कृपया भारत के चुनाव आयोग द्वारा वास्तविक
                                                   घोषणा की प्रतीक्षा करें।
                                                </Typography>
                                             </>
                                          )}
                                       </>
                                    )}
                                 </React.Fragment>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {firstLoadStatus !== 'idle' && elecList.length < 1 && (
               <Alert variant="outlined" severity="warning" sx={{ mx: 5, mt: 5 }}>
                  No election for this body is available for display.
               </Alert>
            )}
         </Box>
      </div>
   )
}

LegisElecs.propTypes = {
   props: PropTypes.object.isRequired,
}

export default LegisElecs
