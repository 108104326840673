import { Box, Card, CardContent, CardMedia, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import PPAllianceBasicInfo from './PPAllianceBasicInfo'
import PPAllianceParties from './PPAllianceParties'
import PPAllianceMeetings from './PPAllianceMeetings'
import { db } from '../../FirebaseConfig'

function PPAlliance() {
   const { pPAllianceId, pPAllianceName } = useParams()
   console.log(pPAllianceId, pPAllianceName)

   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [pPAlliance, setPPAlliance] = useState('')
   // const [alliancePartiesListRoot, setAlliancePartiesListRoot] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('reached first blank use effect')
         fetchPPAlliance(pPAllianceId)
      }
   }, [])

   const fetchPPAlliance = async (pPAllianceId) => {
      try {
         const docRef = doc(db, 'PPAlliances', pPAllianceId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching')
         if (docSnap.exists()) {
            setPPAlliance({ ...docSnap.data(), id: docSnap.id })
            console.log('data fetched: ', docSnap.data())
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      console.log('reached use effect of pPAlliance ..')
      if (pPAlliance !== '') {
         if (pPAllianceId !== pPAlliance.id || pPAllianceName !== pPAlliance.Name) {
            navigate('/', { replace: true })
         } else {
            // setAlliancePartiesListRoot(pPAlliance.Parties)
         }
      } else {
         console.log('use effect of pPAlliance is null: ')
      }
   }, [pPAlliance])

   // const handleAlliancePartiesListRootUpdate = async (newList) => {
   //    console.log('that test: ', newList)
   //    setAlliancePartiesListRoot(newList)
   //    console.log('ordered to  update parties root list: ')
   // }

   const handleAllianceDetails = async (newObject) => {
      console.log('that test: ', newObject)
      setPPAlliance(newObject)
      console.log('ordered to  update parties root list: ')
   }

   const theme = useTheme()
   //
   return (
      <Box sx={{ minHeight: 800, px: 0.5 }}>
         <Card
            sx={{
               my: 1,
               py: 0,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {pPAlliance && (
                     <div>
                        {pPAlliance.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22 }}
                              color="#FFFFFF"
                           >
                              {pPAlliance.NameLocal}
                           </Typography>
                        )}
                        {pPAlliance.NameHindi && pPAlliance.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20 }}
                              color="#FFFFFF"
                           >
                              {pPAlliance.NameHindi}
                           </Typography>
                        )}
                        {pPAlliance.NameHindi && pPAlliance.NameLocal !== '' && (
                           <Typography component="div" sx={{ fontWeight: 700, fontSize: 16 }} color="#FFFFFF">
                              {pPAlliance.NameHindi}
                           </Typography>
                        )}
                        {pPAlliance.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20 }}
                              color="#FFFFFF"
                           >
                              {pPAlliance.Name}
                           </Typography>
                        )}
                        {pPAlliance.NameLocal !== '' && (
                           <Typography component="div" sx={{ fontWeight: 700, fontSize: 16 }} color="#FFFFFF">
                              {pPAlliance.Name}
                           </Typography>
                        )}
                        <Box>
                           {/* <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box> */}
                           {pPAlliance.FoundedOn && (
                              <Box
                                 sx={{
                                    display: 'inline',
                                    mx: 0.5,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: '#ffffff',
                                 }}
                              >
                                 Founded: {moment(pPAlliance.FoundedOn.toDate()).format('Do MMMM YYYY')}
                              </Box>
                           )}
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            {pPAlliance.Logo && (
               <CardMedia
                  component="img"
                  sx={{ width: 151, borderRadius: 1, mr: 1 }}
                  image={pPAlliance.Logo}
                  alt="Live from space album cover"
               />
            )}
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  // centered
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Parties \n राजनीतिक दल`} {...a11yProps(1)} />
                  <StyledTab1 label={`Meetings \n बैठक`} {...a11yProps(2)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {pPAlliance && <PPAllianceBasicInfo pPAlliance={pPAlliance} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {pPAlliance && (
                              <PPAllianceParties
                                 pPAlliance={pPAlliance}
                                 // alliancePartiesListRoot={alliancePartiesListRoot}
                                 // handleAlliancePartiesListRootUpdate={handleAlliancePartiesListRootUpdate}
                                 handleAllianceDetails={handleAllianceDetails}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {pPAlliance && <PPAllianceMeetings />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={4}>
                  xs=4
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default PPAlliance
