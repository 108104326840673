import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   Link,
   List,
   ListItem,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddElecManifesto from './AddElecManifesto'
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
import moment from 'moment'

function ElecManifestoes({ props }) {
   const elec = props
   console.log('value from props from inside Elec Manifestoes page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // related to manifestoes list
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [manifestoesList, setManifestoesList] = useState([])
   const [partiesList, setPartiesList] = useState([])

   const fetchElec = async () => {
      // Note:
      // manifestoes are already here in the elec object
      // however after adding a new manifesto, there was difficulty in displaying the new date under moment
      // hence this fucntion which will be used after adding a new manifesto
      try {
         const docRef = doc(db, 'Elections', elec.id)
         const docSnap = await getDoc(docRef)

         const sortedManifestoes = [...docSnap.data().Manifestoes].sort((a, b) => {
            if (b.DatePublished > a.DatePublished) {
               return -1
            } else if (b.DatePublished < a.DatePublished) {
               return 1
            } else {
               return 0
            }
         })
         setManifestoesList(sortedManifestoes)
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         // const sortedManifestoes = [...elec.Manifestoes].sort((a, b) => {
         //    if (b.DatePublished > a.DatePublished) {
         //       return -1
         //    } else if (b.DatePublished < a.DatePublished) {
         //       return 1
         //    } else {
         //       return 0
         //    }
         // })
         setManifestoesList(elec.Manifestoes)
         setFirstLoadStatus('success')
      }
   }, [])

   // related to form filling and submission of manifestoes

   const [openModalAddManifesto, setOpenModalAddManifesto] = useState(false)

   const handleOpenModalAddManifesto = async (e) => {
      e.preventDefault()

      if (partiesList.length > 0) {
         setOpenModalAddManifesto(true)
      } else {
         /// fetch parties list
         const docRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Parties].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setPartiesList(sortedList)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document for parties!')
         }
      }
   }

   useEffect(() => {
      if (partiesList && partiesList.length > 0) {
         setOpenModalAddManifesto(true)
      }
   }, [partiesList])

   const handleCloseModalAddManifesto = () => setOpenModalAddManifesto(false)

   const [lastManifesto, setLastManifesto] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddManifesto = async (manifestoDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (manifestoDetails.Name.length > 3) {
               manifestoDetails.CrBy = auth.currentUser.uid
               manifestoDetails.CrDt = new Date()
               manifestoDetails.CrIP = ip.data.ip

               manifestoDetails.Election_id = elec.id
               manifestoDetails.ElectionName = elec.Name
               manifestoDetails.ElectionNameHindi = elec.NameHindi
               manifestoDetails.ElectionNameLocal = elec.NameLocal
               manifestoDetails.Country_id = elec.Country_id
               manifestoDetails.State_id = elec.State_id
               manifestoDetails.Division_id = elec.Division_id
               manifestoDetails.District_id = elec.District_id
               manifestoDetails.SubDistrict_id = elec.SubDistrict_id
               manifestoDetails.Block_id = elec.Block_id
               manifestoDetails.Village_id = elec.Village_id

               // const partyIdsList = []

               const queryAlliance = query(
                  collection(db, 'PPAlliances'),
                  where('Party_id_s', 'array-contains', manifestoDetails.IssuedByParty_id),
               )
               const querySnapshotAlliance = await getDocs(queryAlliance)
               querySnapshotAlliance.forEach(async (docSnap) => {
                  manifestoDetails.Alliances.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     NameHindi: docSnap.data().NameHindi,
                     NameLocal: docSnap.data().NameLocal,
                     Abbreviation: docSnap.data().Abbreviation,
                     Parties: docSnap.data().Parties,
                  })

                  const partyIdsList = [...manifestoDetails.SupportingParties, ...docSnap.data().Party_id_s]
                  const uniquePartyIdsList = partyIdsList.filter((val, id, array) => array.indexOf(val) === id)
                  manifestoDetails.SupportingParties = uniquePartyIdsList
               })

               // manifestoDetails.SupportingParties = partyIdsList

               // const uniquePartyIdsList = partyIdsList.filter((val, id, array) => array.indexOf(val) === id)

               console.log('manifestoDetails 1: ', manifestoDetails)

               setLastManifesto(manifestoDetails.Name.toString())
               setUploading(true)

               console.log('manifestoDetails 2: ', manifestoDetails, ' uploading: ', uploading)

               const record = {
                  Manifesto_id: '',
                  Name: manifestoDetails.Name,
                  NameHindi: manifestoDetails.NameHindi,
                  NameLocal: manifestoDetails.NameLocal,
                  IssuedByParty_id: manifestoDetails.IssuedByParty_id,
                  IssuedByPartyName: manifestoDetails.IssuedByPartyName,
                  IssuedByPartyNameHindi: manifestoDetails.IssuedByPartyNameHindi,
                  IssuedByPartyNameLocal: manifestoDetails.IssuedByPartyNameLocal,
                  IssuedByPartyAbbreviation: manifestoDetails.IssuedByPartyAbbreviation,
                  IssuedByPartyFlag: manifestoDetails.IssuedByPartyFlag,
                  IssuedByAlliance_id: manifestoDetails.IssuedByAlliance_id,
                  IssuedByAllianceName: manifestoDetails.IssuedByAllianceName,
                  IssuedByAllianceNameHindi: manifestoDetails.IssuedByAllianceNameHindi,
                  IssuedByAllianceNameLocal: manifestoDetails.IssuedByAllianceNameLocal,
                  IssuedByAllianceAbbreviation: manifestoDetails.IssuedByAllianceAbbreviation,
                  IssuedByAllianceLogo: manifestoDetails.IssuedByAllianceLogo,
                  SupportingParties: manifestoDetails.SupportingParties,
                  Election_id: elec.id,
                  ElectionName: elec.Name,
                  ElectionNameHindi: elec.NameHindi,
                  ElectionNameLocal: elec.NameLocal,
                  Country_id: elec.Country_id,
                  State_id: elec.State_id,
                  Division_id: elec.Division_id,
                  District_id: elec.District_id,
                  SubDistrict_id: elec.SubDistrict_id,
                  Block_id: elec.Block_id,
                  Village_id: elec.Village_id,
                  Alliances: manifestoDetails.Alliances,
                  DateReleased: manifestoDetails.DateReleased,
                  Image: '',
               }

               try {
                  console.log('add start')
                  let recordNew = {}

                  if (manifestoDetails.Image !== '') {
                     const imageRef = ref(
                        dbStorage,
                        `ElectionManifestoes/${elec.id}/${manifestoDetails.Image.name + v4()}`,
                     )
                     await uploadBytes(imageRef, manifestoDetails.Image).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (url) => {
                           manifestoDetails = {
                              ...manifestoDetails,
                              Image: url,
                              ImagesOld: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           }

                           const docRef = await addDoc(collection(db, 'ElectionManifestoes'), manifestoDetails)
                           manifestoDetails.id = docRef.id

                           recordNew = { ...record, Manifesto_id: docRef.id, Image: url }
                           console.log('record new after iamge upload: ', recordNew)

                           const docRefUpdateElection = doc(db, 'Elections', elec.id)
                           await updateDoc(docRefUpdateElection, {
                              Manifestoes: arrayUnion(recordNew),
                           })

                           const docRefUpdateBase = doc(db, 'BaseLists', 'ManifestoesBase-000001')
                           await updateDoc(docRefUpdateBase, {
                              Manifestoes: arrayUnion(recordNew),
                           })

                           setLastManifesto(manifestoDetails.Name)
                        })
                     })
                  } else {
                     const docRef = await addDoc(collection(db, 'ElectionManifestoes'), manifestoDetails)
                     manifestoDetails.id = docRef.id

                     recordNew = { ...record, Manifesto_id: docRef.id }
                     console.log('record new without iamge upload: ', recordNew)

                     const docRefUpdateElection = doc(db, 'Elections', elec.id)
                     await updateDoc(docRefUpdateElection, {
                        Manifestoes: arrayUnion(recordNew),
                     })

                     const docRefUpdateBase = doc(db, 'BaseLists', 'ManifestoesBase-000001')
                     await updateDoc(docRefUpdateBase, {
                        Manifestoes: arrayUnion(recordNew),
                     })

                     setLastManifesto(manifestoDetails.Name)
                  }

                  // Note:
                  // setManifestoesList([...manifestoesList, manifestoDetails]) was causing problem / error for date adjustment in the list
                  // hence, on insert, we fetch the election data afresh, as follows

                  fetchElec()

                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  console.log('Error adding electione', error)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && (
            <Box>
               <Box sx={{ display: 'flex', px: 1, mt: 1 }}>
                  {(isSuper || isDataEditor) && (
                     <Button
                        onClick={(e) => {
                           handleOpenModalAddManifesto(e)
                        }}
                        size="small"
                        variant="outlined"
                        sx={{ py: 0, px: 1, minWidth: 0 }}
                     >
                        Add a manifesto
                     </Button>
                  )}
               </Box>
               <Box sx={{ m: 1 }}>
                  {manifestoesList && manifestoesList.length > 0 ? (
                     <List dense sx={{ py: 0.5 }}>
                        {manifestoesList.map((item, index) => (
                           <Box key={item.Manifesto_id}>
                              <ListItem sx={{ p: 0, ml: 0 }} key={item.Manifesto_id}>
                                 <ListItemText
                                    sx={{ ml: 1, fontSize: 13, p: 0 }}
                                    secondaryTypographyProps={{
                                       fontSize: 12,
                                       color: 'Crimson',
                                       pr: 1,
                                       align: 'left',
                                    }}
                                    primaryTypographyProps={{ fontSize: 12 }}
                                    primary={
                                       <>
                                          <Link
                                             href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <strong>{item.Name.toUpperCase()}</strong>
                                          </Link>

                                          {item.NameLocal && (
                                             <>
                                                <br />
                                                <Link
                                                   href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <strong>{item.NameLocal.toUpperCase()}</strong>
                                                </Link>
                                             </>
                                          )}
                                          {!item.NameLocal && (
                                             <>
                                                <br />
                                                <Link
                                                   href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <strong>{item.NameHindi.toUpperCase()}</strong>
                                                </Link>
                                             </>
                                          )}
                                       </>
                                    }
                                    secondary={
                                       <>
                                          {item.DateReleased && (
                                             <>{moment(item.DateReleased.toDate()).format('Do MMM YYYY')}</>
                                          )}
                                          {item.IssuedByParty_id && <>{item.IssuedByPartyName}</>}
                                          {item.IssuedByAlliance_id && <>{item.IssuedByAllianceName}</>}
                                       </>
                                    }
                                 />
                              </ListItem>
                              <Divider />
                           </Box>
                        ))}
                     </List>
                  ) : (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              Manifestoes to be available soon. <br /> घोषणा पत्र शीघ्र उपलब्ध होंगे |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}
               </Box>
               <Box id="BoxModals">
                  <Modal
                     open={openModalAddManifesto}
                     onClose={handleCloseModalAddManifesto}
                     aria-labelledby="modal-modal-title-addmanifesto"
                     aria-describedby="modal-modal-description-addmanifesto"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-addmanifesto"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add a manifesto
                        </Typography>
                        <Box>
                           <AddElecManifesto
                              partiesList={partiesList}
                              lastManifesto={lastManifesto}
                              uploading={uploading}
                              uploaded={uploaded}
                              uploadingFailed={uploadingFailed}
                              handleAddManifesto={handleAddManifesto}
                           ></AddElecManifesto>
                        </Box>
                     </Box>
                  </Modal>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecManifestoes.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecManifestoes
