import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Link,
   Slide,
   Stack,
   Typography,
} from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import HomeTabPanelLegis from './HomeTabPanelLegis'
import HomeTabPanelExecutive from './HomeTabPanelExecutive'
import HomeTabPanelJudiciary from './HomeTabPanelJudiciary'

import Marquee from 'react-fast-marquee'
import moment from 'moment'
import { sxTypoGraphy2 } from '../../Utils/MUITheme/MUITheme'
// import MydbOperations from '../MydbOperations/MydbOperations'

// const Item = styled(Paper)(({ theme }) => ({
//    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//    ...theme.typography.body2,
//    padding: theme.spacing(1),
//    textAlign: 'center',
//    color: theme.palette.text.secondary,
//    height: 70,
// }))

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function Home() {
   const [initialState, setInitialState] = useState('idle')
   const [partiesListNational, setPartiesListNational] = useState('')
   const [electionsList, setElectionsList] = useState('')

   useEffect(() => {
      if (initialState === 'idle') {
         fetchPartiesListNational()
         fetchElections()
         setInitialState('success')
      }
   }, [])

   // related to countries
   // const countries = useSelector(selectCountries).countriesList
   // const countryId = useSelector(selectCountries).selectedCountryInformation.id

   // related to parties list

   const fetchPartiesListNational = async () => {
      try {
         const docId = 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4'

         let partiesListBase = []
         const docRef = doc(db, 'BaseLists', docId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            partiesListBase = docSnap.data().Parties
            console.log('parties list fetched : ', partiesListBase)
         }

         const partiesListFiltered = partiesListBase.filter((party) => party.ECIStatus === 'National')
         const sortedListParties = partiesListFiltered.sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setPartiesListNational(sortedListParties)

         console.log('parties list fetched by National level: ', sortedListParties)
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchElections = async () => {
      try {
         let electionsListBase = []
         const docRef = doc(db, 'BaseLists', 'Elections-India-000001')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            electionsListBase = docSnap.data().Elections
            console.log('elections list fetched : ', electionsListBase)
         }

         const dateLimit = new Date()
         dateLimit.setMonth(dateLimit.getMonth() - 1)
         console.log('date limit : ', dateLimit)

         const electionsListFiltered = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
         )
         const electionsListFiltered1 = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
         )
         const electionsListFiltered2 = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isBefore(moment(dateLimit)),
         )

         console.log('elections list filtered : ', electionsListFiltered)
         console.log('elections list filtered 1 : ', electionsListFiltered1)
         console.log('elections list filtered 2 : ', electionsListFiltered2)

         const sortedListElections = electionsListFiltered.sort((a, b) => {
            if (moment(b.DateStart.toDate()).isAfter(moment(a.DateStart.toDate()))) {
               return -1
            } else if (moment(b.DateStart.toDate()).isBefore(moment(a.DateStart.toDate()))) {
               return 1
            } else {
               return 0
            }
         })
         setElectionsList(sortedListElections)

         console.log('Elections list sorted: ', sortedListElections)
      } catch (err) {
         console.log('error', err)
      }
   }

   // for dialog students union

   const [openSU, setOpenSU] = React.useState(false)

   const handleClickOpenSU = () => {
      setOpenSU(true)
   }

   const handleCloseSU = () => {
      setOpenSU(false)
   }

   // for dialog leader profile

   const [openLeaderProfile, setOpenLeaderProfile] = React.useState(false)

   const handleClickOpenLeaderProfile = () => {
      setOpenLeaderProfile(true)
   }

   const handleCloseLeaderProfile = () => {
      setOpenLeaderProfile(false)
   }

   // for dialog own party

   const [openOwnParty, setOpenOwnParty] = React.useState(false)

   const handleClickOpenOwnParty = () => {
      setOpenOwnParty(true)
   }

   const handleCloseOwnParty = () => {
      setOpenOwnParty(false)
   }

   // for dialog OnLineProtest

   const [openOnLineProtest, setOpenOnLineProtest] = React.useState(false)

   const handleClickOpenOnLineProtest = () => {
      setOpenOnLineProtest(true)
   }

   const handleCloseOnLineProtest = () => {
      setOpenOnLineProtest(false)
   }

   return (
      <Box id="mainBoxHome">
         <Grid container sx={{ mt: 1, mx: 1 }} disableEqualOverflow>
            <Grid xs={12}>
               <Marquee pauseOnHover="true">
                  {electionsList &&
                     electionsList.length > 0 &&
                     electionsList.map((item) => (
                        <Link
                           key={item.Election_id}
                           href={`/election/${item.Name}/${item.Election_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <Box
                              key={item.Election_id}
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 // backgroundColor: '#dcff9c',
                                 // backgroundColor: '#D0D3D5',
                                 // backgroundColor: '#D5D0D3',
                                 backgroundColor: '#F2F0EB',
                                 mr: 5,
                                 borderRadius: 1,
                                 px: 1,
                                 py: 0.5,
                                 lineHeight: 1,
                              }}
                           >
                              <Typography sx={{ fontSize: 15, lineHeight: 1.1, color: '#000000' }}>
                                 {item.Name}
                              </Typography>
                              {item.IsSpeculated && (
                                 <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Box
                                       sx={{
                                          fontSize: 14,
                                          lineHeight: 1.1,
                                          fontWeight: 500,
                                          backgroundColor: '#FFD32C',
                                          borderRadius: 1,
                                          p: 0.5,
                                          color: '#000000',
                                       }}
                                    >
                                       <span> Speculated </span>
                                    </Box>
                                    <Box sx={{ fontSize: 14, lineHeight: 1.1, color: '#FF5733 ', fontWeight: 500 }}>
                                       {moment(item.DateStart.toDate()).format('Do MMM YYYY')}
                                       {moment(item.DateStart.toDate()).format('Do MMM YYYY') !==
                                          moment(item.DateFinish.toDate()).format('Do MMM YYYY') && (
                                          <> - {moment(item.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                       )}
                                    </Box>

                                    <Box
                                       sx={{
                                          fontSize: 13,
                                          lineHeight: 1.1,
                                          fontWeight: 600,
                                          backgroundColor: '#FFD32C',
                                          borderRadius: 1,
                                          p: 0.5,
                                          color: '#000000',
                                       }}
                                    >
                                       <span> अनुमानित </span>
                                    </Box>
                                 </Box>
                              )}
                              {!item.IsSpeculated && (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.1, color: '#FF5733 ', fontWeight: 500 }}>
                                    {moment(item.DateStart.toDate()).format('Do MMM YYYY')}
                                    {moment(item.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(item.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(item.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Typography>
                              )}
                              {item.NameLocal ? (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.3, color: '#000000' }}>
                                    {item.NameLocal}
                                 </Typography>
                              ) : (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.3, color: '#000000' }}>
                                    {item.NameHindi}
                                 </Typography>
                              )}
                           </Box>
                        </Link>
                     ))}
               </Marquee>
            </Grid>
         </Grid>

         <Grid container sx={{ mt: 1, mx: 0.5 }} disableEqualOverflow>
            <Grid xs={12}>
               <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  {partiesListNational &&
                     partiesListNational.length > 0 &&
                     partiesListNational.map((item) => (
                        <Link
                           key={item.Party_id}
                           href={`/political-party/${item.Name}/${item.Party_id}`}
                           sx={{ textDecoration: 'none !important', px: 0 }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <Box
                              key={item.Party_id}
                              component="img"
                              src={item.Flag}
                              alt={item.Name}
                              sx={{ height: '50px', maxWidth: 150, borderRadius: 1 }}
                           />
                        </Link>
                     ))}
               </Stack>
            </Grid>
         </Grid>

         <Grid container sx={{ mx: 1, backgroundColor: '#000000' }} disableEqualOverflow>
            <Grid xs={12}>
               <Marquee>
                  <Typography sx={{ fontSize: 15, color: '#CFFF04', fontWeight: 500, mr: 5 }}>
                     {`World's first of its kind digital platform - For a transparent democratic interaction between
                     leaders - elected representatives (MP, MLA, Ministers, Corporaters, etc.) - political parties -
                     student unions - government - administration - judiciary - PUBLIC.`}
                  </Typography>
                  <Typography sx={{ fontSize: 15, color: '#FF9933', fontWeight: 500, mr: 5 }}>
                     {`Made In India - Owned By Indians - Run By Indians`}
                  </Typography>
                  <Typography sx={{ fontSize: 14, color: '#CFFF04', fontWeight: 600, mr: 5 }}>
                     विश्व का अपनी तरह का पहला डिजिटल प्लेटफार्म - नेताओं, निर्वाचित प्रतिनिधि (सांसद, विधायक, मंत्री,
                     कॉर्पोरेटर, आदि) - राजनीतिक दल - छात्र संघ - सरकार - प्रशासन - न्यायपालिका - जनता के बीच एक
                     लोकतांत्रिक पारदर्शी पारस्परिक संवाद के लिए|
                  </Typography>
                  <Typography sx={{ fontSize: 14, color: '#FF9933', fontWeight: 600, mr: 5 }}>
                     भारत में निर्मित - भारतीयों के स्वामित्व में - भारतीयों द्वारा संचालित
                  </Typography>
               </Marquee>
            </Grid>
         </Grid>

         <Grid id="home_grid_container_2" container spacing={0.5} sx={{ my: 0.25, mx: 0.5 }} disableEqualOverflow>
            <Grid
               id="home_grid_Container_2_child_1"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 1, sm: 1, md: 1 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Legislative (विधायिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelLegis />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_2"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 2, sm: 4, md: 3 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Executive (कार्यपालिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelExecutive />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_3"
               xs={4}
               sm={4}
               md={2}
               sx={{ alignItems: 'center', justifyContent: 'center', mt: 0.5, borderRadius: 1 }}
               order={{ xs: 3, sm: 2, md: 2 }}
            >
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="error"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        whiteSpace: 'pre-line',
                     }}
                  >
                     <Link
                        href={`/constitution/India/lsTDGRF8XHWnR3VjVuB4`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 12,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <strong>संविधान</strong> {'\n'} Constitution
                     </Link>
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        whiteSpace: 'pre-line',
                     }}
                  >
                     <Link
                        href={`/political-parties/India/lsTDGRF8XHWnR3VjVuB4`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 12,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <strong>राजनीतिक दल</strong> {'\n'} Political Parties
                     </Link>
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={handleClickOpenSU}
                  >
                     छात्र संघ {'\n'} Student Unions
                  </Button>
                  <Dialog
                     open={openSU}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleCloseSU}
                     aria-describedby="alert-dialog-slide-su"
                  >
                     <DialogTitle
                        sx={{
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >{`छात्र संघ ${'\n'} Student Unions`}</DialogTitle>
                     <DialogContent>
                        <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                           This facility will be available soon. {'\n'}
                           यह सुविधा शीघ्र ही उपलब्ध होगी |
                        </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={handleCloseSU}>Close</Button>
                     </DialogActions>
                  </Dialog>
               </Box>
               {/* <Box sx={{ width: '100%', mt: 0.5 }}>
                  <ThemeProvider theme={theme}>
                     <Button
                        variant="contained"
                        size="small"
                        color="ochre"
                        sx={{
                           textTransform: 'none',
                           padding: 1,
                           minWidth: 0,
                           lineHeight: 1.1,
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 12,
                           whiteSpace: 'pre-line',
                        }}
                        onClick={(e) => {
                           handleClickScams(e)
                        }}
                     >
                        घोटाले {'\n'} Scams
                     </Button>
                  </ThemeProvider>
               </Box> */}
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="error"
                     size="small"
                     sx={{
                        backgroundColor: '#a41941',
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={handleClickOpenLeaderProfile}
                  >
                     नेता प्रोफाइल बनायें {'\n'} Create Leader Profile
                  </Button>
                  <Dialog
                     open={openLeaderProfile}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleCloseLeaderProfile}
                     aria-describedby="alert-dialog-slide-description"
                  >
                     <DialogTitle
                        sx={{
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >{`नेता प्रोफाइल बनायें ${'\n'} Create Leader Profile`}</DialogTitle>
                     <DialogContent>
                        <DialogContentText
                           id="alert-dialog-slide-description"
                           sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}
                        >
                           This facility will be available soon. {'\n'}
                           यह सुविधा शीघ्र ही उपलब्ध होगी |
                        </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={handleCloseLeaderProfile}>Close</Button>
                     </DialogActions>
                  </Dialog>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="error"
                     size="small"
                     sx={{
                        backgroundColor: '#a41941',
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={handleClickOpenOwnParty}
                  >
                     अपना दल बनायें {'\n'} Create Own Party
                  </Button>
                  <Dialog
                     open={openOwnParty}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleCloseOwnParty}
                     aria-describedby="alert-dialog-slide-OwnParty"
                  >
                     <DialogTitle
                        sx={{
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >{`अपना दल बनायें ${'\n'} Create Own Party`}</DialogTitle>
                     <DialogContent>
                        <DialogContentText
                           id="alert-dialog-slide-description"
                           sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}
                        >
                           This facility will be available soon. {'\n'}
                           यह सुविधा शीघ्र ही उपलब्ध होगी |
                        </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={handleCloseOwnParty}>Close</Button>
                     </DialogActions>
                  </Dialog>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="warning"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={handleClickOpenOnLineProtest}
                  >
                     ऑनलाइन विरोध शुरू करें {'\n'} Satrt On-line Protest
                  </Button>
                  <Dialog
                     open={openOnLineProtest}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleCloseOnLineProtest}
                     aria-describedby="alert-dialog-slide-OwnParty"
                  >
                     <DialogTitle
                        sx={{
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >{`ऑनलाइन विरोध शुरू करें ${'\n'} Satrt On-line Protest`}</DialogTitle>
                     <DialogContent>
                        <DialogContentText
                           id="alert-dialog-slide-description"
                           sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}
                        >
                           This facility will be available soon. {'\n'}
                           यह सुविधा शीघ्र ही उपलब्ध होगी |
                        </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={handleCloseOnLineProtest}>Close</Button>
                     </DialogActions>
                  </Dialog>
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_4"
               xs={6}
               sm={4}
               md={2}
               order={{ xs: 4, sm: 3, md: 4 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 2,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Your Constituencies (आपके निर्वाचन क्षेत्र)</Typography>
               </Box>
               <Grid container spacing={1} sx={{ my: 0.5 }} disableEqualOverflow>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Parliamentary (संसदीय):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        State Assembly (राज्य विधायिका):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        District Level (जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Sub-District Level (उप-जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Block Level (ब्लॉक स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Base Level (मूल स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_5"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 5, sm: 5, md: 5 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Judiciary (न्यायपालिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelJudiciary />
               </Box>
            </Grid>
         </Grid>

         {/* <HomeAccordian /> */}
         {/* <MydbOperations /> */}
         <br />
         <br />
         <br />
         <br />
      </Box>
   )
}

export default Home
