import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button } from '@mui/material'
// import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

function ElecCandiCred({ props }) {
   const candidate = props

   const user = useSelector(selectUsers)
   const ip = user.ip

   // const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   console.log('value from props from inside Elec Candi Cred : ', candidate, ip, thisUser)

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [expanded, setExpanded] = useState(false)
   const [qualificationsList, setQualificationsList] = useState([])
   const [politicalAffiliationsList, setPoliticalAffiliations] = useState([])
   const [nGOAffiliationsList, setNGOAffiliations] = useState([])

   const handleChangeAccordianExpand = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   const credList = [
      { Name: 'Education / Qualification', Code: 'Edu', id: 5 },
      { Name: 'Political Affiliations', Code: 'Pol', id: 10 },
      { Name: 'Legal Cases', Code: 'Leg', id: 15 },
      { Name: 'Sports', Code: 'Spo', id: 20 },
      { Name: 'NGO Affiliations', Code: 'NGO', id: 25 },
      { Name: 'Occupations', Code: 'Occ', id: 30 },
      { Name: 'Businesses', Code: 'Bus', id: 35 },
      { Name: 'Relatives', Code: 'Rlt', id: 40 },
   ]

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setQualificationsList([...candidate.Qualifications].sort((a, b) => a.YearStart - b.YearStart))
         setPoliticalAffiliations([...candidate.PoliticalAffiliations].sort((a, b) => a.YearStart - b.YearStart))
         setNGOAffiliations([...candidate.NGO].sort((a, b) => a.YearStart - b.YearStart))

         const item = credList[0]
         setExpanded(item.id)
         setFirstLoadStatus('success')
      }
   }, [])

   return (
      <Box>
         <Box sx={{ display: 'flex', px: 1, mt: 1 }}>
            {(isSuper || isDataEditor) && (
               <Button
                  // onClick={(e) => {
                  //    handleOpenModalAddPhase(e)
                  // }}
                  size="small"
                  variant="outlined"
                  sx={{ py: 0, px: 1, minWidth: 0 }}
               >
                  Add item
               </Button>
            )}
         </Box>
         <Box sx={{ m: 1 }}>
            {credList &&
               credList.length > 0 &&
               credList.map((item, index) => (
                  <Accordion
                     key={item.id}
                     expanded={expanded === item.id}
                     onChange={handleChangeAccordianExpand(item.id)}
                     defaultExpanded={index === 0}
                     sx={{
                        mt: 0.5,
                        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                     }}
                  >
                     <AccordionSummary
                        expandIcon={<ArrowDropDownIcon style={{ color: '#FFFFFF' }} />}
                        aria-controls={`${item.PhaseNum}-content`}
                        id={`${item.PhaseNum}-header`}
                        sx={{
                           // backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363)',
                           backgroundImage: 'linear-gradient(to right, #48464c, #605d66, #eb9191, #eb9191, #efa7a7)',
                           color: '#FFFFFF',
                        }}
                     >
                        <strong>{item.Name}</strong>
                     </AccordionSummary>
                     <AccordionDetails>
                        {item.Code === 'Edu' && (
                           <Box>
                              {qualificationsList &&
                                 qualificationsList.length > 0 &&
                                 qualificationsList.map((item, index) => <div key={index}>{item.Institute}</div>)}
                           </Box>
                        )}
                        {item.Code === 'Pol' && (
                           <Box>
                              {politicalAffiliationsList &&
                                 politicalAffiliationsList.length > 0 &&
                                 politicalAffiliationsList.map((item, index) => (
                                    <div key={index}>{item.PartyName}</div>
                                 ))}
                           </Box>
                        )}
                        {item.Code === 'Leg' && (
                           <Box>
                              {candidate.LegalCases &&
                                 candidate.LegalCases.length > 0 &&
                                 candidate.LegalCases.map((item, index) => <div key={index}>{item.PoliceStation}</div>)}
                           </Box>
                        )}
                        {item.Code === 'Spo' && (
                           <Box>
                              {candidate.Sports &&
                                 candidate.Sports.length > 0 &&
                                 candidate.Sports.map((item, index) => <div key={index}>{item.Description}</div>)}
                           </Box>
                        )}
                        {item.Code === 'NGO' && (
                           <Box>
                              {nGOAffiliationsList &&
                                 nGOAffiliationsList.length > 0 &&
                                 nGOAffiliationsList.map((item, index) => <div key={index}>{item.NGOName}</div>)}
                           </Box>
                        )}
                        {item.Code === 'Occ' && (
                           <Box>
                              {candidate.Occupations &&
                                 candidate.Occupations.length > 0 &&
                                 candidate.Occupations.map((item, index) => <div key={index}>{item.Description}</div>)}
                           </Box>
                        )}
                        {item.Code === 'Bus' && (
                           <Box>
                              {candidate.Businesses &&
                                 candidate.Businesses.length > 0 &&
                                 candidate.Businesses.map((item, index) => <div key={index}>{item.Description}</div>)}
                           </Box>
                        )}
                        {item.Code === 'Rlt' && (
                           <Box>
                              {candidate.Relatives &&
                                 candidate.Relatives.length > 0 &&
                                 candidate.Relatives.map((item, index) => <div key={index}>{item.Description}</div>)}
                           </Box>
                        )}
                     </AccordionDetails>
                     <AccordionActions>
                        {(isSuper || isDataEditor) && (
                           <>
                              {item.Code === 'Edu' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add education
                                 </Button>
                              )}
                              {item.Code === 'Pol' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add political affiliation
                                 </Button>
                              )}
                              {item.Code === 'Leg' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add legal case
                                 </Button>
                              )}
                              {item.Code === 'Spo' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add sports
                                 </Button>
                              )}
                              {item.Code === 'NGO' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add NGO
                                 </Button>
                              )}
                              {item.Code === 'Occ' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add occupation
                                 </Button>
                              )}
                              {item.Code === 'Bus' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add business
                                 </Button>
                              )}
                              {item.Code === 'Rlt' && (
                                 <Button
                                    // onClick={(e) => {
                                    //    handleOpenModalAddPhase(e)
                                    // }}
                                    size="small"
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                 >
                                    Add relative
                                 </Button>
                              )}
                           </>
                        )}
                     </AccordionActions>
                  </Accordion>
               ))}
         </Box>
      </Box>
   )
}

ElecCandiCred.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecCandiCred
