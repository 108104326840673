import React, { useState, useEffect } from 'react'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'

import myStyles from './GoTop.module.css'

const GoTop = () => {
   const [showTopBtn, setShowTopBtn] = useState(false)

   useEffect(() => {
      window.addEventListener('scroll', () => {
         if (window.scrollY > 400) {
            setShowTopBtn(true)
         } else {
            setShowTopBtn(false)
         }
      })
   }, [])

   const goToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      })
   }

   return (
      <div className={myStyles.top_to_btm}>
         {' '}
         {showTopBtn && (
            <ArrowCircleUpIcon
               sx={{ borderRadius: '50%', height: 50, width: 50 }}
               className={[myStyles.icon_position, myStyles.icon_style]}
               onClick={goToTop}
            />
         )}{' '}
      </div>
   )
}
export default GoTop
