import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Avatar,
   Badge,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Radio,
   RadioGroup,
   Select,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import PeopleIcon from '@mui/icons-material/People'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'

import AddConstisToPhase from './AddConstisToPhase'
import EditSeat from './EditSeat'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'

function ElecConstis({ elec, legis, handleConstituencyCandidatesLink, constisListRoot, handleConstisListRootUpdate }) {
   console.log('value from props from inside Elec Constis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [phasesList, setPhasesList] = useState([])
   const [constisListFromLegis, setConstisListFromLegis] = useState([])
   const [constisListAvailable, setConstisListAvailable] = useState([])

   const [constisList, setConstisList] = useState([])
   const [constisListRootLocal, setConstisListRootLocal] = useState([])

   const [selectedPhase, setSelectedPhase] = useState('')

   const [openModalEditSeat, setOpenModalEditSeat] = useState(false)
   const [uploadingSeat, setUploadingSeat] = useState(false)
   const [uploadedSeat, setUploadedSeat] = useState(false)
   const [uploadingFailedSeat, setUploadingFailedSeat] = useState(false)
   const [selectedConsti, setSelectedConsti] = useState('')
   const [selectedConstiName, setSelectedConstiName] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //

         // we are fetching the following for import of constis from legislature to election
         const sortedConstisListFromLegis = [...legis.Constituencies].sort(
            (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
         )
         setConstisListFromLegis(sortedConstisListFromLegis)
         setConstisListRootLocal(constisListRoot)

         const sortedPhases = [...elec.Phases].sort((a, b) => a.PhaseNum - b.PhaseNum)
         setPhasesList(sortedPhases)

         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      // the phase list will change only once on page load, hence ...
      if (phasesList && phasesList.length > 0) {
         const item = phasesList[0]
         setSelectedPhase(item.PhaseNum)
      }
   }, [phasesList])

   const handleChangePhase = async (e) => {
      e.preventDefault()

      setSelectedPhase(e.target.value)
   }

   useEffect(() => {
      if (selectedPhase) {
         setConstisList(constisListRootLocal.filter((item) => item.PhaseNum === selectedPhase))
         setSortField('Name')
      }
   }, [selectedPhase])

   const [sortField, setSortField] = useState('Name')

   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (sortField) {
         let sortedConstis
         switch (sortField) {
            case 'Name':
               sortedConstis = [...constisList].sort((a, b) => {
                  if (b.Name > a.Name) {
                     return -1
                  } else if (b.Name < a.Name) {
                     return 1
                  } else {
                     return 0
                  }
               })
               break
            case 'ConstituencyNumber':
               sortedConstis = [...constisList].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
               break
         }

         setConstisList(sortedConstis)
      }
   }, [sortField])

   // related to form filling and submission of constis

   const [openModalAddConstisToPhase, setOpenModalAddConstisToPhase] = useState(false)

   const handleOpenModalAddConstisToPhase = (e) => {
      e.preventDefault()

      if (constisListFromLegis && constisListRootLocal.length > 0) {
         // Note: the following code collects all the Constituency_is from the constisListRootLocal
         const filterIds = new Set(constisListRootLocal.map((item) => item.Constituency_id))

         // Note: the following code filters out all the filterIds from the constisListFromLegis
         const filteredArray = constisListFromLegis.filter((item) => !filterIds.has(item.Constituency_id))

         setConstisListAvailable(filteredArray)
      } else {
         setConstisListAvailable(constisListFromLegis)
      }

      setOpenModalAddConstisToPhase(true)
   }

   const handleCloseModalAddConstisToPhase = () => setOpenModalAddConstisToPhase(false)

   const [uploadingConstis, setUploadingConstis] = useState(false)
   const [uploadedConstis, setUploadedConstis] = useState(false)
   const [uploadingFailedConstis, setUploadingFailedConstis] = useState(false)

   const handleAddConstisToPhase = async (constisListSelected) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (constisListSelected.length > 0) {
               try {
                  setUploadingConstis(true)
                  const tempListRoot = constisListRootLocal
                  const tempListConsti = constisList

                  const docRef = doc(db, 'Elections', elec.id)

                  constisListSelected.forEach(async (item) => {
                     const thisConstituency = constisListFromLegis.find(
                        (itemConsti) => itemConsti.Constituency_id === item,
                     )

                     thisConstituency.CrBy = auth.currentUser.uid
                     thisConstituency.CrDt = new Date()
                     thisConstituency.CrIP = ip.data.ip
                     thisConstituency.IsDeleted = false
                     thisConstituency.PhaseNum = selectedPhase

                     thisConstituency.RemovedFromPhase = false
                     thisConstituency.RemovedFromPhaseDate = ''
                     thisConstituency.RemovedFromPhaseBy = ''
                     thisConstituency.RemovedFromPhaseByDate = ''

                     thisConstituency.SeatType = ''
                     thisConstituency.SeatTypeChanged = false
                     thisConstituency.SeatTypeChangedDate = ''
                     thisConstituency.SeatTypeChangedBy = ''
                     thisConstituency.SeatTypeChangedByDate = ''

                     await updateDoc(docRef, {
                        Constituencies: arrayUnion(thisConstituency),
                     })

                     tempListRoot.push(thisConstituency)
                     console.log('temp list root:', tempListRoot)

                     tempListConsti.push(thisConstituency)
                     console.log('temp list consti:', tempListConsti)
                  })

                  setConstisListRootLocal(tempListRoot)
                  handleConstisListRootUpdate(tempListRoot)
                  console.log('consti list root 2:', tempListRoot)
                  // since the constis from legis are being added inside a selected phase only ...
                  setConstisList(tempListConsti)
                  console.log('consti list consti 2:', tempListConsti)

                  setUploadingConstis(false)
                  setUploadedConstis(true)
                  setUploadingFailedConstis(false)

                  setOpenModalAddConstisToPhase(false)
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  console.log('Error adding electione', error)
                  setUploadingConstis(false)
                  setUploadedConstis(false)
                  setUploadingFailedConstis(true)

                  setOpenModalAddConstisToPhase(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (
      e,
      thisConstituencyId,
      thisLegislatureId,
      thisLegislatureName,
      thisConstituencyNumber,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisNameAlt,
      thisStateName,
      thisStateId,
      thisDivisionName,
      thisDivisionId,
      thisDistrictName,
      thisDistrictId,
      thisSubDistrictId,
      thisSubDistrictName,
      thisBlockId,
      thisBlockName,
      thisVillageId,
      thisVillageName,
      thisParentConstiId,
      thisParentConstiName,
      thisIsNominated,
      thisStrata,
      thisIsDeleted,
      thisPhaseNum,
      thisCrBy,
      thisCrDt,
      thisCrIP,
      thisRemovedFromPhase,
      thisRemovedFromPhaseDate,
      thisRemovedFromPhaseBy,
      thisRemovedFromPhaseByDate,
      thisSeatType,
      thisSeatTypeChanged,
      thisSeatTypeChangedDate,
      thisSeatTypeChangedBy,
      thisSeatTypeChangedByDate,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this constitency?')) {
               try {
                  const docRefUpdate = doc(db, 'Elections', elec.id)
                  const record = {
                     Constituency_id: thisConstituencyId,
                     Legislature_id: thisLegislatureId,
                     LegislatureName: thisLegislatureName,
                     ConstituencyNumber: thisConstituencyNumber,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     NameAlt: thisNameAlt,
                     StateName: thisStateName,
                     State_id: thisStateId,
                     DivisionName: thisDivisionName,
                     Division_id: thisDivisionId,
                     DistrictName: thisDistrictName,
                     District_id: thisDistrictId,
                     SubDistrict_id: thisSubDistrictId,
                     SubDistrictName: thisSubDistrictName,
                     Block_id: thisBlockId,
                     BlockName: thisBlockName,
                     Village_id: thisVillageId,
                     VillageName: thisVillageName,
                     ParentConsti_id: thisParentConstiId,
                     ParentConstiName: thisParentConstiName,
                     IsNominated: thisIsNominated,
                     Strata: thisStrata,
                     IsDeleted: thisIsDeleted,
                     PhaseNum: thisPhaseNum,
                     CrBy: thisCrBy,
                     CrDt: thisCrDt,
                     CrIP: thisCrIP,

                     RemovedFromPhase: thisRemovedFromPhase,
                     RemovedFromPhaseDate: thisRemovedFromPhaseDate,
                     RemovedFromPhaseBy: thisRemovedFromPhaseBy,
                     RemovedFromPhaseByDate: thisRemovedFromPhaseByDate,

                     SeatType: thisSeatType,
                     SeatTypeChanged: thisSeatTypeChanged,
                     SeatTypeChangedDate: thisSeatTypeChangedDate,
                     SeatTypeChangedBy: thisSeatTypeChangedBy,
                     SeatTypeChangedByDate: thisSeatTypeChangedByDate,
                  }
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove(record),
                  })

                  const constisListFiltered = constisList.filter((item) => item.Constituency_id !== thisConstituencyId)
                  const constisListRootLocalFiltered = constisListRootLocal.filter(
                     (item) => item.Constituency_id !== thisConstituencyId,
                  )
                  setConstisList(constisListFiltered)
                  setConstisListRootLocal(constisListRootLocalFiltered)
                  handleConstisListRootUpdate(constisListRootLocalFiltered)
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // realted to seat reservation

   const handleOpenModalEditSeat = (e, ConstituencyId, Name, Number) => {
      e.preventDefault()

      setSelectedConsti(ConstituencyId)
      setSelectedConstiName(Name.toUpperCase() + ' (' + Number + ')')

      setOpenModalEditSeat(true)
   }

   const handleCloseModalEditSeat = () => setOpenModalEditSeat(false)

   const handleEditSeat = async (seatType) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (seatType.length !== '') {
               try {
                  setUploadingSeat(true)

                  const consti = constisList.find((item) => item.Constituency_id === selectedConsti)

                  const recordOld = {
                     Constituency_id: consti.Constituency_id,
                     Legislature_id: consti.Legislature_id,
                     LegislatureName: consti.LegislatureName,
                     ConstituencyNumber: consti.ConstituencyNumber,
                     Name: consti.Name,
                     NameHindi: consti.NameHindi,
                     NameLocal: consti.NameLocal,
                     NameAlt: consti.NameAlt,
                     StateName: consti.StateName,
                     State_id: consti.State_id,
                     DivisionName: consti.DivisionName,
                     Division_id: consti.Division_id,
                     DistrictName: consti.DistrictName,
                     District_id: consti.District_id,
                     SubDistrict_id: consti.SubDistrict_id,
                     SubDistrictName: consti.SubDistrictName,
                     Block_id: consti.Block_id,
                     BlockName: consti.BlockName,
                     Village_id: consti.Village_id,
                     VillageName: consti.VillageName,
                     ParentConsti_id: consti.ParentConsti_id,
                     ParentConstiName: consti.ParentConstiName,
                     IsNominated: consti.IsNominated,
                     Strata: consti.Strata,
                     IsDeleted: consti.IsDeleted,
                     PhaseNum: consti.PhaseNum,
                     CrBy: consti.CrBy,
                     CrDt: consti.CrDt,
                     CrIP: consti.CrIP,

                     RemovedFromPhase: consti.RemovedFromPhase,
                     RemovedFromPhaseDate: consti.RemovedFromPhaseDate,
                     RemovedFromPhaseBy: consti.RemovedFromPhaseBy,
                     RemovedFromPhaseByDate: consti.RemovedFromPhaseByDate,

                     SeatType: consti.SeatType,
                     SeatTypeChanged: consti.SeatTypeChanged,
                     SeatTypeChangedDate: consti.SeatTypeChangedDate,
                     SeatTypeChangedBy: consti.SeatTypeChangedBy,
                     SeatTypeChangedByDate: consti.SeatTypeChangedByDate,
                  }
                  console.log(recordOld)
                  const recordNew = {
                     Constituency_id: consti.Constituency_id,
                     Legislature_id: consti.Legislature_id,
                     LegislatureName: consti.LegislatureName,
                     ConstituencyNumber: consti.ConstituencyNumber,
                     Name: consti.Name,
                     NameHindi: consti.NameHindi,
                     NameLocal: consti.NameLocal,
                     NameAlt: consti.NameAlt,
                     StateName: consti.StateName,
                     State_id: consti.State_id,
                     DivisionName: consti.DivisionName,
                     Division_id: consti.Division_id,
                     DistrictName: consti.DistrictName,
                     District_id: consti.District_id,
                     SubDistrict_id: consti.SubDistrict_id,
                     SubDistrictName: consti.SubDistrictName,
                     Block_id: consti.Block_id,
                     BlockName: consti.BlockName,
                     Village_id: consti.Village_id,
                     VillageName: consti.VillageName,
                     ParentConsti_id: consti.ParentConsti_id,
                     ParentConstiName: consti.ParentConstiName,
                     IsNominated: consti.IsNominated,
                     Strata: consti.Strata,
                     IsDeleted: consti.IsDeleted,
                     PhaseNum: consti.PhaseNum,
                     CrBy: consti.CrBy,
                     CrDt: consti.CrDt,
                     CrIP: consti.CrIP,

                     RemovedFromPhase: consti.RemovedFromPhase,
                     RemovedFromPhaseDate: consti.RemovedFromPhaseDate,
                     RemovedFromPhaseBy: consti.RemovedFromPhaseBy,
                     RemovedFromPhaseByDate: consti.RemovedFromPhaseByDate,

                     SeatType: seatType,
                     SeatTypeChanged: true,
                     SeatTypeChangedDate: '',
                     SeatTypeChangedBy: auth.currentUser.uid,
                     SeatTypeChangedByDate: new Date(),
                  }
                  console.log(recordNew)

                  const docRefUpdate = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove(recordOld),
                  })
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayUnion(recordNew),
                  })

                  const newConstisListFiltered = constisList.filter((item) => item.Constituency_id !== selectedConsti)
                  console.log('newConstisList Filtered: ', newConstisListFiltered)
                  const newConstisListAppended = [...newConstisListFiltered, recordNew]
                  console.log('newConstisList Appended: ', newConstisListAppended)
                  const newConstisListSorted = [...newConstisListAppended].sort((a, b) => {
                     if (b.Name > a.Name) {
                        return -1
                     } else if (b.Name < a.Name) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('newConstisList Sorted: ', newConstisListSorted)

                  const newConstisListRootFiltered = constisListRootLocal.filter(
                     (item) => item.Constituency_id !== selectedConsti,
                  )
                  console.log('newConstisList Root Filtered: ', newConstisListRootFiltered)
                  const newConstisListRootAppended = [...newConstisListRootFiltered, recordNew]
                  console.log('newConstisList Root Appended: ', newConstisListRootAppended)
                  const newConstisListRootSorted = [...newConstisListRootAppended].sort((a, b) => {
                     if (b.Name > a.Name) {
                        return -1
                     } else if (b.Name < a.Name) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('newConstisList Root Sorted: ', newConstisListRootSorted)

                  setConstisList(newConstisListSorted)
                  setConstisListRootLocal(newConstisListRootSorted)
                  handleConstisListRootUpdate(newConstisListRootSorted)

                  setUploadingSeat(false)
                  setUploadedSeat(true)
                  setUploadingFailedSeat(false)

                  setOpenModalEditSeat(false)
               } catch (error) {
                  alert('Error editing seat: ', error.message)
                  console.log('Error editing seat', error)
                  setUploadingSeat(false)
                  setUploadedSeat(false)
                  setUploadingFailedSeat(true)

                  setOpenModalEditSeat(false)
               }
            } else {
               setSelectedConsti('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleSubmitCandidateLink = async (e, constituencyId) => {
      e.preventDefault()

      handleConstituencyCandidatesLink(constituencyId)
   }

   return (
      <Box>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && (
            <Box sx={{ px: 1 }}>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     px: 1,
                     mt: 0.5,
                     '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  }}
               >
                  <Box>
                     <FormControl sx={{ ml: 1 }} size="small">
                        <InputLabel>Phase / चरण</InputLabel>
                        <Select
                           value={selectedPhase}
                           label="Phase / चरण"
                           onChange={(e) => {
                              handleChangePhase(e)
                           }}
                        >
                           {phasesList &&
                              phasesList.length > 0 &&
                              phasesList.map((item) => (
                                 <MenuItem key={item.PhaseNum} value={item.PhaseNum}>
                                    {item.PhaseNum}
                                 </MenuItem>
                              ))}
                        </Select>
                     </FormControl>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 0.5,
                        ml: 'auto',
                        border: 1,
                        borderRadius: 1,
                        borderColor: '#888889',
                     }}
                  >
                     <Box sx={{ color: '#795548', fontSize: 15 }}>Sort by:</Box>
                     <Box>
                        <FormControl
                           sx={{
                              p: 0,
                           }}
                        >
                           {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="SortField"
                              value={sortField}
                              // style={{ display: 'flex', gap: '2rem' }}
                              onChange={(e) => {
                                 handleSortField(e)
                              }}
                              sx={{
                                 backgroundColor: '#ffffff',
                                 borderRadius: 1,
                                 py: 0,
                                 '& .MuiSvgIcon-root': {
                                    fontSize: 15,
                                 },
                                 '& .MuiButtonBase-root': {
                                    py: 0,
                                 },
                                 '& MuiFormControlLabel-label': {
                                    fontSize: 13,
                                    lineHeight: 16,
                                    py: 0,
                                 },
                              }}
                           >
                              <FormControlLabel
                                 value="Name"
                                 control={<Radio size="small" />}
                                 label="Name"
                                 sx={{ py: 0, fontSize: 15 }}
                              />
                              <FormControlLabel
                                 value="ConstituencyNumber"
                                 control={<Radio size="small" />}
                                 label="Constituency number"
                                 sx={{ py: 0, fontSize: 15 }}
                              />
                           </RadioGroup>
                        </FormControl>
                     </Box>
                  </Box>
               </Box>
               <Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        backgroundImage: 'linear-gradient(to right, #48464c, #605d66, #eb9191, #eb9191, #efa7a7)',
                        color: '#ffffff',
                     }}
                  >
                     {phasesList && phasesList.length < 1 && <Box>Constituencies for this election:</Box>}
                     {phasesList && phasesList.length < 1 && <Box>इस चुनाव के लिए निर्वाचन क्षेत्र:</Box>}
                     {phasesList && phasesList.length > 0 && <Box>Constituencies under phase {selectedPhase}:</Box>}
                     {phasesList && phasesList.length > 0 && <Box>चरण {selectedPhase} के निर्वाचन क्षेत्र:</Box>}
                  </Box>
                  {phasesList && phasesList.length < 1 && (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              To be available soon. <br /> शीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}

                  {(isSuper || isDataEditor) && phasesList && phasesList.length > 0 && selectedPhase !== '' && (
                     <Box>
                        <Button
                           sx={{ py: 0, px: 1, minWidth: 0 }}
                           onClick={(e) => {
                              handleOpenModalAddConstisToPhase(e)
                           }}
                        >
                           Add Constituencies
                        </Button>
                     </Box>
                  )}

                  <Box>
                     <List dense>
                        {constisList &&
                           constisList.length > 0 &&
                           constisList.map((item, index) => (
                              <div key={item.Constituency_id}>
                                 <ListItem
                                    key={item.Constituency_id}
                                    secondaryAction={
                                       <Box
                                          sx={{
                                             ml: 'auto',
                                             display: 'flex',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                          }}
                                       >
                                          <Box>
                                             <Button
                                                size="small"
                                                sx={{
                                                   padding: 0,
                                                   minWidth: 0,
                                                   fontSize: 12,
                                                   lineHeight: 1.1,
                                                   whiteSpace: 'pre-line',
                                                }}
                                                endIcon={<PeopleIcon />}
                                                onClick={(e) => {
                                                   handleSubmitCandidateLink(e, item.Constituency_id)
                                                }}
                                             >
                                                Candidates {'\n'} प्रत्याशी
                                             </Button>
                                          </Box>
                                          <Box>
                                             {isSuper && (
                                                <IconButton
                                                   edge="end"
                                                   aria-label="delete"
                                                   onClick={(e) => {
                                                      handleDelete(
                                                         e,
                                                         item.Constituency_id,
                                                         item.Legislature_id,
                                                         item.LegislatureName,
                                                         item.ConstituencyNumber,
                                                         item.Name,
                                                         item.NameHindi,
                                                         item.NameLocal,
                                                         item.NameAlt,
                                                         item.StateName,
                                                         item.State_id,
                                                         item.DivisionName,
                                                         item.Division_id,
                                                         item.DistrictName,
                                                         item.District_id,
                                                         item.SubDistrict_id,
                                                         item.SubDistrictName,
                                                         item.Block_id,
                                                         item.BlockName,
                                                         item.Village_id,
                                                         item.VillageName,
                                                         item.ParentConsti_id,
                                                         item.ParentConstiName,
                                                         item.IsNominated,
                                                         item.Strata,
                                                         item.IsDeleted,
                                                         item.PhaseNum,
                                                         item.CrBy,
                                                         item.CrDt,
                                                         item.CrIP,
                                                         item.RemovedFromPhase,
                                                         item.RemovedFromPhaseDate,
                                                         item.RemovedFromPhaseBy,
                                                         item.RemovedFromPhaseByDate,
                                                         item.SeatType,
                                                         item.SeatTypeChanged,
                                                         item.SeatTypeChangedDate,
                                                         item.SeatTypeChangedBy,
                                                         item.SeatTypeChangedByDate,
                                                      )
                                                   }}
                                                   sx={{ mr: 1, height: '25px', width: '25px' }}
                                                >
                                                   <DeleteIcon fontSize="small" />
                                                </IconButton>
                                             )}
                                          </Box>
                                       </Box>
                                    }
                                 >
                                    <ListItemAvatar>
                                       <Avatar sx={{ height: '30px', width: '30px' }}>
                                          <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                                       </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                       secondaryTypographyProps={{
                                          align: 'left',
                                          whiteSpace: 'pre-line',
                                          fontSize: '13px',
                                       }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             <Link
                                                href={`/constituency/${item.Name}/${item.Constituency_id}`}
                                                sx={{ mr: 1, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong> ({item.ConstituencyNumber}){' '}
                                                {item.NameLocal && <>{item.NameLocal}</>} {item.NameHindi}
                                             </Link>
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             <>
                                                {' Seat: '}
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="#000000"
                                                >
                                                   {item.SeatType}
                                                </Typography>

                                                {(isDataEditor || isSuper) && (
                                                   <IconButton
                                                      edge="end"
                                                      aria-label="edit"
                                                      onClick={(e) => {
                                                         handleOpenModalEditSeat(
                                                            e,
                                                            item.Constituency_id,
                                                            item.Name,
                                                            item.ConstituencyNumber,
                                                         )
                                                      }}
                                                      sx={{ ml: 1, height: '15px', width: '15px' }}
                                                   >
                                                      <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                   </IconButton>
                                                )}
                                                <br />
                                             </>
                                             {item.Strata === 'State' && (
                                                <>
                                                   {' Parent PC: '}
                                                   <Link
                                                      href={`/constituency/${item.ParentConstiName}/${item.ParentConsti_id}`}
                                                      sx={{ mr: 1, textDecoration: 'none !important' }}
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {item.ParentConstiName}
                                                   </Link>
                                                   <br />
                                                </>
                                             )}
                                             {' District: '}
                                             <>
                                                {item.DistrictName.map((itemDis, index) => (
                                                   <>
                                                      {index + 1}.
                                                      <Link
                                                         key={itemDis.District_id}
                                                         href={`/district/${itemDis.Name}/${itemDis.District_id}`}
                                                         sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                         rel="noreferrer"
                                                         target="_blank"
                                                      >
                                                         {itemDis.Name}
                                                      </Link>
                                                   </>
                                                ))}

                                                {/* <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="text.primary"
                                                >
                                                   {item.DistrictName.join(', ')}
                                                </Typography> */}
                                             </>
                                             {item.DivisionName.length > 0 && (
                                                <>
                                                   <br />
                                                   {' Division: '}
                                                   {item.DivisionName.map((itemDiv, index) => (
                                                      <>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemDiv.Division_id}
                                                            href={`/division/${itemDiv.Name}/${itemDiv.Division_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemDiv.Name}
                                                         </Link>
                                                      </>
                                                   ))}
                                                   {/* <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.DivisionName.join(', ')}
                                                   </Typography> */}
                                                </>
                                             )}
                                             {legis.Strata === 'Central' && (
                                                <>
                                                   <br />
                                                   {' State / UT: '}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.StateName}
                                                   </Typography>
                                                </>
                                             )}
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                     </List>
                  </Box>
                  <Box id="boxModals">
                     <Modal
                        open={openModalAddConstisToPhase}
                        onClose={handleCloseModalAddConstisToPhase}
                        aria-labelledby="modal-modal-title-addConstis"
                        aria-describedby="modal-modal-description-addConstis"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addConstis"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              Add constituencies to phase {selectedPhase}
                           </Typography>
                           <AddConstisToPhase
                              constisListAvailable={constisListAvailable}
                              uploadingConstis={uploadingConstis}
                              uploadedConstis={uploadedConstis}
                              uploadingFailedConstis={uploadingFailedConstis}
                              handleAddConstisToPhase={handleAddConstisToPhase}
                           ></AddConstisToPhase>
                        </Box>
                     </Modal>
                     <Modal
                        open={openModalEditSeat}
                        onClose={handleCloseModalEditSeat}
                        aria-labelledby="modal-modal-title-editSeat"
                        aria-describedby="modal-modal-description-editSeat"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-editSeat"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              Edit seat reservation for {'\n'}
                              {selectedConstiName}
                           </Typography>
                           <EditSeat
                              uploadingSeat={uploadingSeat}
                              uploadedSeat={uploadedSeat}
                              uploadingFailedSeat={uploadingFailedSeat}
                              handleEditSeat={handleEditSeat}
                           ></EditSeat>
                        </Box>
                     </Modal>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecConstis.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
   constisListRoot: PropTypes.array.isRequired,

   handleConstituencyCandidatesLink: PropTypes.func.isRequired,
   handleConstisListRootUpdate: PropTypes.func.isRequired,
}

export default ElecConstis
