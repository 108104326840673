import React, { useEffect } from 'react'
// import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import { useSelector } from 'react-redux'

import './App.css'
// eslint-disable-next-line no-unused-vars
import app, { auth } from './FirebaseConfig'

// #region Imports Basic
import Home from './Pages/Home/Home'
import Layout from './Pages/Layout'
// #endregion

// #region Imports Legis
import Legis from './Pages/Legis/Legis/Legis'
import LegisAll from './Pages/Legis/LegisAll/LegisAll'
import LegisAllAdmin from './Pages/Legis/LegisAllAdmin/LegisAllAdmin'
// #endregion

// #region Imports Elec
import Elec from './Pages/Election/Elec/Elec'
// #endregion

// #region Imports Election candidate
import ElecCandi from './Pages/ElecCandi/ElecCandi/ElecCandi'
// #endregion

// #region Imports PP
import PPartiesAdmin from './Pages/PParties/PParties/PPartiesAdmin'
import PParties from './Pages/PParties/PParties/PParties'

import PParty from './Pages/PParties/PParty/PParty'
// #endregion

// #region Imports Gen

import About from './Pages/Terms/About/About'
import Contact from './Pages/Terms/Contact/Contact'
import Privacy from './Pages/Terms/Privacy/Privacy'
import Terms from './Pages/Terms/Terms/Terms'
import NoPage from './Pages/NoPage'
import Login from './Pages/Login/Login'
// #endregion

// #region Imports Geo
import CountriesAdmin from './Pages/GeoDivisions/CountriesAdmin'
import StatesAdmin from './Pages/GeoDivisions/StatesAdmin'
import DistrictsAdmin from './Pages/GeoDivisions/DistrictsAdmin'
import DivisionsAdmin from './Pages/GeoDivisions/DivisionsAdmin'
import Constitution from './Pages/Constitution/Constitution/Constitution'
// #endregion

import { setIP, setUser } from './redux/reducers/users-slice'
import { useDispatch, useSelector } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { fetchCountries, selectCountries } from './redux/reducers/countries-slice'
import axios from 'axios'

import PinCodes from './Pages/GeoDivisions/PinCodes'
import AdminRoles from './Pages/Admin/AdminRoles'
import SubDistrictsAdmin from './Pages/GeoDivisions/SubDistrictsAdmin'
import BlocksAdmin from './Pages/GeoDivisions/BlocksAdmin'
import VillagesAdmin from './Pages/GeoDivisions/VillagesAdmin'
import TempSubDistrictAdmin from './Pages/MydbOperations/TempSubDistrictAdmin'
import TempBlockAdmin from './Pages/MydbOperations/TempBlockAdmin'
import District from './Pages/GeoDivisions/Districts/District'
import State from './Pages/GeoDivisions/States/State'
import Division from './Pages/GeoDivisions/Divisions/Division'
import Block from './Pages/GeoDivisions/Blocks/Block'
import SubDistrict from './Pages/GeoDivisions/SubDistricts/SubDistrict'
import Village from './Pages/GeoDivisions/Villages/Village'
import Constituency from './Pages/Constituencies/Constituency'
import PPAlliance from './Pages/PPAlliances/PPAlliance'
import ElecManifesto from './Pages/ElecManifesto/ElecManifesto'
import GoTop from './Utils/CustomButtons/GoTop'
import Ministry from './Pages/Ministry/Ministry/Ministry'
import President from './Pages/President/President/President'
import Governor from './Pages/Governor/Governor/Governor'

function App() {
   const dispatch = useDispatch()

   const countryStatus = useSelector(selectCountries).status

   useEffect(() => {
      onAuthStateChanged(auth, (user) => {
         if (user) {
            if (
               user.uid === 'fOvFUvIMs0bq5GXtqoKvygKOqsJ2' ||
               user.uid === '858yEVzzRZdXGGQKbCwnkAD6pLk1' ||
               user.uid === 'Lgm457d2FINaSDFS9ds48RGBaGm1'
            ) {
               dispatch(
                  setUser({
                     id: user.uid,
                     email: user.email,
                     displayName: user.displayName,
                     superAdmin: true,
                     dataAdmin: true,
                  }),
               )
            } else if (user.uid === 'abc' || user.uid === 'def' || user.uid === 'geh') {
               dispatch(
                  setUser({
                     id: user.uid,
                     email: user.email,
                     displayName: user.displayName,
                     superAdmin: false,
                     dataAdmin: true,
                  }),
               )
            } else {
               dispatch(
                  setUser({
                     id: user.uid,
                     email: user.email,
                     displayName: user.displayName,
                     superAdmin: false,
                     dataAdmin: false,
                  }),
               )
            }
         } else {
            dispatch(setUser(null))
         }
         handleIP()
      })

      if (countryStatus === 'idle') {
         // this method is applied to prevent infinite loop. Ch 6 at 5.10 min. DaWeb Ivan
         dispatch(fetchCountries())
      }
   }, [])

   const handleIP = async (e) => {
      try {
         const ip = await axios.get('https://ipapi.co/json')
         dispatch(setIP(ip))
      } catch (error) {
         console.error(error)
      }
   }
   return (
      <div className="mainDivApp">
         <GoTop />
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />

                  <Route path="constitution/:countryName/:countryId" element={<Constitution />} />

                  <Route path="election/:elecName/:elecId" element={<Elec />} />
                  <Route path="election/:elecName/manifesto/:manifestoId" element={<ElecManifesto />} />

                  <Route path="election/candidate/:candiName/:candiId" element={<ElecCandi />} />

                  <Route path="geo-political-divisions/countries/admin" element={<CountriesAdmin />} />
                  <Route path="geo-political-divisions/states/admin" element={<StatesAdmin />} />
                  <Route path="geo-political-divisions/divisions/admin" element={<DivisionsAdmin />} />
                  <Route path="geo-political-divisions/districts/admin" element={<DistrictsAdmin />} />
                  <Route path="geo-political-divisions/subDistricts/admin" element={<SubDistrictsAdmin />} />
                  <Route path="geo-political-divisions/blocks/admin" element={<BlocksAdmin />} />
                  <Route path="geo-political-divisions/villages/admin" element={<VillagesAdmin />} />
                  <Route path="geo-political-divisions/pin-codes/admin" element={<PinCodes />} />

                  <Route path="state/:stateName/:statetId" element={<State />} />
                  <Route path="division/:divisionName/:divisionId" element={<Division />} />
                  <Route path="district/:districtName/:districtId" element={<District />} />
                  <Route path="block/:blockName/:blockId" element={<Block />} />
                  <Route path="subDistrict/:subDistrictName/:subDistrictId" element={<SubDistrict />} />
                  <Route path="village/:villageName/:villageId" element={<Village />} />

                  <Route path="legislatures" element={<LegisAll />} />
                  <Route path="legislatures/admin" element={<LegisAllAdmin />} />
                  <Route path="legislature/:legisName/:legisId" element={<Legis />} />
                  <Route path="executive-central/:legisName/:legisId" element={<President />} />
                  <Route path="executive-state/:legisName/:legisId" element={<Governor />} />

                  <Route path="ministry/:legisName/:legisId" element={<Ministry />} />

                  <Route path="constituency/:constituencyName/:constituencyId" element={<Constituency />} />

                  <Route path="login" element={<Login />} />

                  <Route path="political-parties/admin" element={<PPartiesAdmin />} />
                  <Route path="political-parties/:countryName/:countryId" element={<PParties />} />
                  <Route path="political-party/:pPartyName/:pPartyId" element={<PParty />} />

                  <Route path="alliance/:pPAllianceName/:pPAllianceId" element={<PPAlliance />} />

                  <Route path="temp-admin/subdistrict" element={<TempSubDistrictAdmin />} />
                  <Route path="temp-admin/block" element={<TempBlockAdmin />} />

                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="terms" element={<Terms />} />

                  <Route path="admin/admin-roles" element={<AdminRoles />} />

                  <Route path="*" element={<NoPage />} />
               </Route>
            </Routes>
         </BrowserRouter>
      </div>
   )
}

export default App

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(<App />)
