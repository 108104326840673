import { Box, Card, CardContent, CardMedia, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import moment from 'moment'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import ElecBasicInfo from '../ElecBasicInfo/ElecBasicInfo'
import ElecConstis from '../ElecConstis/ElecConstis'
import ElecCandis from '../ElecCandis/ElecCandis'
import ElecManifestoes from '../ElecManifestoes/ElecManifestoes'
import ElecOrders from '../ElecOrders/ElecOrders'
import ElecStarCampaigners from '../ElecStarCampaigners/ElecStarCampaigners'
import ElecFilters from '../ElecFilters/ElecFilters'

function Elec() {
   const { elecId, elecName } = useParams()
   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
      setConstituencyId('')
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
      setConstituencyId('')
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [elec, setElec] = useState('')
   const [legis, setLegis] = useState({})
   const [candidatesListRoot, setCandidatesListRoot] = useState([])
   const [constisListRoot, setConstisListRoot] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('reached first blank use effect')
         fetchElec(elecId)
      }
   }, [])

   const fetchElec = async (elecId) => {
      try {
         const docRef = doc(db, 'Elections', elecId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching')
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      console.log('reached use effect of elec ..')
      if (elec !== '') {
         if (elecId !== elec.id || elecName !== elec.Name) {
            navigate('/', { replace: true })
         } else {
            fetchLegis(elec.Legislature_id)
            fetchCadidates()
            const sortedConstisListRoot = [...elec.Constituencies].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setConstisListRoot(sortedConstisListRoot)
         }
      } else {
         console.log('use effect of elec is null: ')
      }
   }, [elec])

   const fetchLegis = async (LegislatureId) => {
      try {
         const docRef = doc(db, 'Legislatures', LegislatureId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching legislature')
         if (docSnap.exists()) {
            setLegis({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error fetchinf elec', err)
      }
   }

   const fetchCadidates = async () => {
      try {
         if (elec.CandidatesCoreLists.length > 0) {
            const candisFromCoreListFetched = []

            elec.CandidatesCoreLists.forEach(async (item) => {
               const docRefCore = doc(db, 'ElectionCandidates', item)
               const docSnapCore = await getDoc(docRefCore)
               console.log('data: ', docSnapCore.data().Candidates)
               candisFromCoreListFetched.push(...docSnapCore.data().Candidates)
            })
            setCandidatesListRoot(candisFromCoreListFetched)
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      console.log('reached use effect of legis ..')
      if (legis !== '') {
         //
      } else {
         console.log('use effect of legis is null: ')
      }
   }, [legis])

   // constituencies
   const [constituencyId, setConstituencyId] = useState('')

   const handleConstituencyCandidatesLink = async (constituencyId) => {
      console.log('received order to open candidates link: ', constituencyId)
      setConstituencyId('')
      setConstituencyId(constituencyId)
      console.log('ordered to open candidates link: ', constituencyId)
   }

   const handleConstisListRootUpdate = async (newList) => {
      setConstisListRoot(newList)
      console.log('ordered to  update constis root list: ')
   }

   // candidates

   const handleCandidatesListRootUpdate = async (newList) => {
      setCandidatesListRoot(newList)
   }

   useEffect(() => {
      if (fetchStatus !== 'idle' && constituencyId !== '') {
         setValue(2)
      }
   }, [constituencyId])

   const theme = useTheme()
   //
   return (
      <Box sx={{ minHeight: 800, px: 0.5 }}>
         <Card
            sx={{
               my: 1,
               py: 0,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box
               sx={{
                  // display: 'flex',
                  // flexDirection: 'column',
                  px: 1,
                  py: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                  {elec && (
                     <div>
                        {elec.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, lineHeight: 1.1 }}
                              color="#FFFFFF"
                           >
                              {elec.NameLocal}
                           </Typography>
                        )}
                        {elec.NameHindi && elec.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, lineHeight: 1.1 }}
                              color="#FFFFFF"
                           >
                              {elec.NameHindi}
                           </Typography>
                        )}
                        {elec.NameHindi && elec.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.1 }}
                              color="#FFFFFF"
                           >
                              {elec.NameHindi}
                           </Typography>
                        )}
                        {elec.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, lineHeight: 1.1 }}
                              color="#FFFFFF"
                           >
                              {elec.Name}
                           </Typography>
                        )}
                        {elec.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.1 }}
                              color="#FFFFFF"
                           >
                              {elec.Name}
                           </Typography>
                        )}
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateStart.toDate()).format('Do MMMM YYYY')}

                              {moment(elec.DateStart.toDate()).format('Do MMMM YYYY') !==
                                 moment(elec.DateFinish.toDate()).format('Do MMMM YYYY') && (
                                 <> - {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}</>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1 }}
               image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               alt="Live from space album cover"
            />
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  // centered
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     ml: 5,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Constituencies \n निर्वाचन क्षेत्र`} {...a11yProps(1)} />
                  <StyledTab1 label={`Candidates \n प्रत्याशी`} {...a11yProps(2)} />
                  <StyledTab1 label={`Filter \n निस्यंदन`} {...a11yProps(3)} />
                  <StyledTab1 label={`Manifestoes \n घोषणा पत्र`} {...a11yProps(4)} />
                  <StyledTab1 label={`Orders \n आदेश`} {...a11yProps(5)} />
                  <StyledTab1 label={`Star Campaigners \n स्टार प्रचारक`} {...a11yProps(6)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {elec && <ElecBasicInfo props={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {elec && (
                              <ElecConstis
                                 elec={elec}
                                 legis={legis}
                                 constisListRoot={constisListRoot}
                                 handleConstituencyCandidatesLink={handleConstituencyCandidatesLink}
                                 handleConstisListRootUpdate={handleConstisListRootUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {elec && (
                              <ElecCandis
                                 legis={legis}
                                 elec={elec}
                                 constituencyId={constituencyId}
                                 candidatesListRoot={candidatesListRoot}
                                 handleCandidatesListRootUpdate={handleCandidatesListRootUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {elec && <ElecFilters legis={legis} elec={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {elec && <ElecManifestoes props={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={5} dir={theme.direction}>
                           {elec && <ElecOrders props={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={6} dir={theme.direction}>
                           {elec && <ElecStarCampaigners props={elec} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4}>
                  xs=4
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Elec
